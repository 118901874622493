import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map, Observable, pipe, Subject, takeUntil } from 'rxjs';
import { SINGLETON_INJECTABLE_TYPE } from '../../common/models/constants.model';
import { environment } from '../../environments/environment';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { ApplicationUserDto } from '../models/application-user.dto.model';
import { SessionStorageService } from './sessionStorage.service';
import { UserRoleTypes } from '../models/user-role-types.model';

@Injectable({
  providedIn: SINGLETON_INJECTABLE_TYPE,
})
export class AuthService implements OnDestroy {

    public appUser: ApplicationUserDto | null = null;
    public accountInfo: AccountInfo | null = null;
    private readonly _destroying$ = new Subject<void>();
    private _authenticated$ = new Subject<void>();
    public onAuthenticated = this._authenticated$.asObservable();

    public userRoleTypes: UserRoleTypes = new UserRoleTypes();

    private readonly SESSION_STORAGE_EMPLOYEE_NUMBER: string = "ProjectPortfolioReview.EmployeeNumber";
    private readonly SESSION_STORAGE_NAME: string = "ProjectPortfolioReview.Name";
    private readonly SESSION_STORAGE_USERNAME: string = "ProjectPortfolioReview.Username";
    public readonly SESSION_STORAGE_IMPERSONATED_PM_EMPLOYEE_NUMBER: string = "ProjectPortfolioReview.ImpersonatedPMEmployeeNumber";
    public readonly SESSION_STORAGE_IMPERSONATED_TASKMANAGER_EMPLOYEE_NUMBER: string = "ProjectPortfolioReview.ImpersonatedTaskManagerEmployeeNumber";

    constructor(public http: HttpClient, protected msalAuthService: MsalService, protected msalBroadcastService: MsalBroadcastService, protected sessionStorageService: SessionStorageService) {
        this.userRoleTypes.PMEmployeeNumber = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_IMPERSONATED_PM_EMPLOYEE_NUMBER);
        this.userRoleTypes.isPM = this.userRoleTypes.PMEmployeeNumber != null && this.userRoleTypes.PMEmployeeNumber.trim() != "";
        this.userRoleTypes.taskManagerEmployeeNumber = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_IMPERSONATED_TASKMANAGER_EMPLOYEE_NUMBER);
        this.userRoleTypes.isTaskManager = this.userRoleTypes.taskManagerEmployeeNumber != null && this.userRoleTypes.taskManagerEmployeeNumber.trim() != "";
        this.userRoleTypes.taskManagerEmployeeNumber = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_IMPERSONATED_TASKMANAGER_EMPLOYEE_NUMBER);
    }

    public ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    public getUserInfo(loadFromCacheOnly: boolean = false): Observable<any> {
        const observableValue = new Observable<ApplicationUserDto | null>((observer: any) => {
             this.msalAuthService.handleRedirectObservable().subscribe(() => {
                return this.msalBroadcastService.inProgress$
                .pipe(
                    filter((status: InteractionStatus) => status === InteractionStatus.None),
                    takeUntil(this._destroying$)
                )
                .subscribe({ next: () => {
                    if (this.accountInfo == null) {
                        let accounts = this.msalAuthService.instance.getAllAccounts();
                        if (accounts.length) {
                            this.accountInfo = accounts[0];
                            this.msalAuthService.instance.setActiveAccount(this.accountInfo);
                        }
                    }
                    this.updateAppUserFromSessionStorage(loadFromCacheOnly);
                    let impersonatedPMEmpNumber = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_IMPERSONATED_PM_EMPLOYEE_NUMBER);
                    let impersonatedTaskManagerEmpNumber = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_IMPERSONATED_TASKMANAGER_EMPLOYEE_NUMBER);

                  let retrieveUserInfoFromServer: boolean = this.appUser?.employeeNumber == null || impersonatedPMEmpNumber != null ||
                    (!this.userRoleTypes.isAdmin && !this.userRoleTypes.isElevated && !this.userRoleTypes.isGeneralRoleType && !this.userRoleTypes.isPM && !this.userRoleTypes.isTaskManager && !this.userRoleTypes.isSupervisor);

                    if ((!retrieveUserInfoFromServer || loadFromCacheOnly) && !impersonatedPMEmpNumber && !impersonatedTaskManagerEmpNumber) {
                        if (this.accountInfo != null && this.appUser?.employeeNumber == null) {
                            this.appUser = new ApplicationUserDto(this.accountInfo.username, this.accountInfo.name, undefined);
                        }

                        this._authenticated$.next();
                        observer.next(this.appUser);
                    } else {
                        this.http.get<any>(`${environment.baseServiceUrl}/api/auth/userInfo`).subscribe({next: (result: ApplicationUserDto) => {
                            this.appUser = result;
                            this.userRoleTypes = result.roleTypes;
                            if (result.employeeNumber) {
                                this.sessionStorageService.setStringItem(this.SESSION_STORAGE_EMPLOYEE_NUMBER, result.employeeNumber);
                            }

                            if (result.name) {
                                this.sessionStorageService.setStringItem(this.SESSION_STORAGE_NAME, result.name);
                            }
                            this.sessionStorageService.setStringItem(this.SESSION_STORAGE_USERNAME, result.username);

                            this._authenticated$.next();
                            observer.next(this.appUser);
                        },
                        error: (error: any) => observer.error(error) });
                    }
                }, error: (error: any) => {
                    observer.error(error);
                }})
            });
        });
        return observableValue;
    }

    public login(): void {
        this.msalAuthService.loginRedirect();
    }

    public logoff(): void {
        this.sessionStorageService.removeItem(this.SESSION_STORAGE_EMPLOYEE_NUMBER);
        this.sessionStorageService.removeItem(this.SESSION_STORAGE_NAME);
        this.sessionStorageService.removeItem(this.SESSION_STORAGE_USERNAME);
        this.msalAuthService.logoutRedirect({ account: this.accountInfo });
    }

    private updateAppUserFromSessionStorage(loadFromCacheOnly: boolean): void {
        if (this.appUser == null || this.appUser.employeeNumber == null || loadFromCacheOnly) {
            const employeeNumber = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_EMPLOYEE_NUMBER);
            if (employeeNumber != null) {
                if (this.appUser == null) {
                    const name = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_NAME);
                    const username = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_USERNAME);

                    if (username != null) {
                        this.appUser = new ApplicationUserDto(username, name, employeeNumber);
                    }
                } else {
                    this.appUser.employeeNumber = employeeNumber;
                }
            }
        }
    }

    public forceUserInfoRetrievalFromServer(): void {
        this.sessionStorageService.removeItem(this.SESSION_STORAGE_EMPLOYEE_NUMBER);
        this.sessionStorageService.removeItem(this.SESSION_STORAGE_NAME);
        this.sessionStorageService.removeItem(this.SESSION_STORAGE_USERNAME);
    }
}
