import { ProjectFilterView } from "./project-filter-view.model";

export class LightweightProjectFilterView {
    public filterViewKey!: string | null;
    public filterViewName!: string;
    public flagTypeIds: number[] = [];
    public officeIds: string[] = [];
    public departmentIds: string[] = [];
    public ownerClientTypeIds: string[] = [];
    public isDesignBuild: boolean = false;
    public isActiveProjectStatus: boolean = true;
    public isCompletedProjectStatus: boolean = false;
    public isDefault: boolean = false;
    public projectManager1DepartmentCodes: string[] = [];
    public projectManager1OfficeCodes: string[] = [];
    public projectManager1EmployeeNumbers: string[] = [];
    public projectManager2EmployeeNumbers: string[] = [];

    public mapTo(filterView: ProjectFilterView): LightweightProjectFilterView {
        this.departmentIds = filterView.departments.map(x => x.id);
        this.officeIds = filterView.offices.map(x => x.id);
        this.flagTypeIds = filterView.flagTypes.map(x => x.id);
        this.ownerClientTypeIds = filterView.ownerClientTypes.map(x => x.id);
        this.isDesignBuild = filterView.isDesignBuild;
        this.isActiveProjectStatus = filterView.isActiveProjectStatus;
        this.isCompletedProjectStatus = filterView.isCompletedProjectStatus;
        this.filterViewKey = filterView.filterViewKey;
        this.filterViewName = filterView.filterViewName;
        this.isDefault = filterView.isDefault;
        this.projectManager1DepartmentCodes = filterView.projectManager1Departments.map(x => x.departmentCode);
        this.projectManager1OfficeCodes = filterView.projectManager1Offices.map(x => x.officeCode);
        this.projectManager1EmployeeNumbers = filterView.projectManager1Employees.map(x => x.employeeNumber);
        this.projectManager2EmployeeNumbers = filterView.projectManager2Employees.map(x => x.employeeNumber);

        return this;
    }
}