import { Pipe, PipeTransform } from "@angular/core";

/**
 * @description
 * Returns the viewbox for an image cell used in the ImageCellRendererComponent
 *
 * @param {string} iconWidth - width of icon
 * @param {string} iconHeight - width of height
**/
@Pipe({ name: "imageCellViewbox" })
export class ImageCellViewboxPipe implements PipeTransform {

  constructor() {
  }

  //Formats viewbox for an image cell based on given icon width and icon height
  public transform(iconWidth: string, iconHeight: string) {
    let viewbox = "";
    if (iconWidth && iconHeight) {
      viewbox = `0 0 ${iconWidth.replace("px", "")} ${iconHeight.replace("px", "")}`;
    }
    return viewbox;
  };
}
