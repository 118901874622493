import { ICellRenderer, ICellRendererParams } from "ag-grid-enterprise";
import { Component } from "@angular/core";
import { IImageCellRendererParams } from "./iimage-cell-renderer-params";

@Component({
  selector: 'image-cell-renderer',
  templateUrl: 'image-cell-renderer.component.html',
  styleUrls: ['./image-cell-renderer.component.scss'],
})
export class ImageCellRendererComponent implements ICellRenderer<IImageCellRendererParams> {

  params!: any;
  imageCellParams: Array<IImageCellRendererParams> = [];
  public rendererIconURL!: string;
  public rendererIconURLs: Array<string> = [];
  public text!: string;
  public textCssClass!: string;
  public imageCssClass!: string;
  public imageTitle: string = '';
  public iconHeight: string = '';
  public iconWidth: string = ''
  public viewBox: string = '';
  public isYellowFlag: boolean = false;
  private readonly RED_FLAG_HEIGHT: string = "17px";
  private readonly RED_FLAG_WIDTH: string = "17px";
  private readonly YELLOW_FLAG_HEIGHT: string = "19px";
  private readonly YELLOW_FLAG_WIDTH: string = "15px";
  private readonly CHECK_HEIGHT: string = "13px";
  private readonly CHECK_WIDTH: string = "23px";

  agInit(params: any): void {
    this.params = params;
    this.setParameters();
  }

  setParameters() {
    if (this.params.imageCellParams) {
      this.imageCellParams = this.params.imageCellParams;
      for (let param of this.imageCellParams) {
        this.setHeightWidthViewBox(param);
        param.rendererImageFilename = param.rendererImageFilename ? "assets/sprites/sprite.svg#" + param.rendererImageFilename.replace(".svg", "") : "";
        const textFieldName = param.textFieldName;
        param.text = this.params.data != null ? this.params.data[textFieldName] : '';
        param.imageTitle = param.imageTitle != '' && param.imageTitle != null ? param.imageTitle : param.text;
      }
    }
  }

  private setHeightWidthViewBox(param: IImageCellRendererParams): void {
    switch (param.rendererImageFilename) {
      case "icon-red-flag-light-bg.svg":
        param.iconHeight = this.RED_FLAG_HEIGHT;
        param.iconWidth = this.RED_FLAG_WIDTH;
        break;
      case "icon-yellow-flag-grid.svg":
        param.iconHeight = this.YELLOW_FLAG_HEIGHT;
        param.iconWidth = this.YELLOW_FLAG_WIDTH;
        param.isYellowFlag = true;
        break;
      case "icon-action-checks.svg":
        param.iconHeight = this.CHECK_HEIGHT;
        param.iconWidth = this.CHECK_WIDTH;
        break;
      default:
        console.log("Missed case in image-cell-renderer.component > setHeightWidth, rendererImageFilename = " + param.rendererImageFilename);
    }
    this.viewBox = "0 0 " + this.iconWidth.replace("px", "") + " " + this.iconHeight.replace("px", "");
  }

  refresh(params: IImageCellRendererParams): boolean {
    this.params = params;
    this.setParameters();
    return true;
}
}
