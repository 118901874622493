import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { finalize, Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { EmployeeService } from '../../../core/services/employee.service';
import { environmentAll } from '../../../environments/environment-all';
import { AuthService } from '../../../core/services/auth.service';
@Component({
  selector: 'release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit, OnDestroy {
  private subscriptions = new Array<Subscription>();

  public releaseNotesTemplate!: SafeHtml;
  public lastReleaseVersionViewedByUser!: number;

  private readonly RELEASE_VERSION_DECREMENT: number = 0.1;

  public get displayReleaseNotes(): boolean {
    return this.releaseNotesTemplate != null && (this.lastReleaseVersionViewedByUser == null || this.lastReleaseVersionViewedByUser < environmentAll.releaseVersion);
  }

  @ViewChild("releaseNotesModal") releaseNotesModal!: ElementRef;
  @ViewChild("closeModalButton") closeModalButton!: ElementRef;

  constructor(private domSanitizer: DomSanitizer, private httpClient: HttpClient, private employeeService: EmployeeService, private authService: AuthService) {

  }

  public ngOnInit(): void {
    let currentReleaseVersionInIteration = environmentAll.releaseVersion;
    let releaseNotesHtml: string = '';

    if (this.authService.appUser?.employeeNumber != null && this.authService.appUser?.releaseNotesVersionViewed < environmentAll.releaseVersion) {
      this.lastReleaseVersionViewedByUser = this.authService.appUser?.releaseNotesVersionViewed != null ? this.authService.appUser?.releaseNotesVersionViewed : environmentAll.earliestReleaseVersion - this.RELEASE_VERSION_DECREMENT;

      this.setReleaseNotesHtml(releaseNotesHtml, currentReleaseVersionInIteration);
    }
  }

  private setReleaseNotesHtml(releaseNotesHtml: string, currentReleaseVersionInIteration: number) {

    if (this.lastReleaseVersionViewedByUser < environmentAll.releaseVersion) {
      const getReleaseNotesSub = this.httpClient.get(`./assets/release-notes/release-${currentReleaseVersionInIteration}.html`, { responseType: 'text' })
        .pipe(finalize(() => {
          this.subscriptions.push(getReleaseNotesSub);
        }))
        .subscribe((data) => {
          releaseNotesHtml += data;
          console.log(currentReleaseVersionInIteration);
          currentReleaseVersionInIteration = currentReleaseVersionInIteration - this.RELEASE_VERSION_DECREMENT;

          while (environmentAll.ignoredReleaseVersions.some(version => version === currentReleaseVersionInIteration)) {
            currentReleaseVersionInIteration = currentReleaseVersionInIteration - this.RELEASE_VERSION_DECREMENT;
            //NOTE: This is needed due to floating point accuracy errors (e.g. 1.7 - 0.1 = 1.5999999999999999  instead of 1.6)   https://en.wikipedia.org/wiki/Floating-point_arithmetic#Accuracy_problems
            currentReleaseVersionInIteration = parseFloat(currentReleaseVersionInIteration.toPrecision(2));
          }

          if (currentReleaseVersionInIteration <= this.lastReleaseVersionViewedByUser) {
            this.releaseNotesTemplate = this.domSanitizer.bypassSecurityTrustHtml(releaseNotesHtml);
            setTimeout(() => {
              this.releaseNotesModal.nativeElement.showModal();
              this.closeModalButton.nativeElement.focus();
            });

          } else {
            this.setReleaseNotesHtml(releaseNotesHtml, currentReleaseVersionInIteration);
          }
        });
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

  }

  public closeReleaseNotesModal(): void {
    const updateViewedReleaseNotes = this.employeeService.updateEmployeeHasViewedReleaseNotes().pipe(finalize(() => {
      this.subscriptions.push(updateViewedReleaseNotes);
    })).subscribe({next: () => {
      this.releaseNotesModal.nativeElement.close();
    },
    error: () => {
      this.releaseNotesModal.nativeElement.close();
    }});
  }

}

