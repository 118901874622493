import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

/**
 * @description
 * Format a currency to display for an accounting balance sheet.
 * E.g. negative numbers display as ($1000.00)
 * 
 * @param {string} value - number to format
 * @param {DecimalPipe} decimalPipe - The decimal pipe 
**/
@Pipe({ name: "accountingCurrency" })
export class AccountingCurrencyPipe implements PipeTransform {

  constructor(protected decimalPipe: DecimalPipe) {
  }

  //Formats currency to have at least 1 digit before the decimal point and exactly 2 digits after the decimal point (e.g. 0.00)
  private readonly DIGITS_INFO_FORMAT: string = '1.2-2'
  
  public transform(value: number, includeDollarSign: boolean = true) {
    if (value == null || value == undefined) {
      value = 0.00;
    }
    let isNegativeNumber: boolean = value < 0;
    let formattedDecimal = this.decimalPipe.transform(value, this.DIGITS_INFO_FORMAT);

    let result = isNegativeNumber ? `($${formattedDecimal?.replace('-', '')})` : `$${formattedDecimal}`;
    if (!includeDollarSign) {
      result = result.replace('$', '');
    }
    return result;
  };
}
