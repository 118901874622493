import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { finalize, Observable, Subscription } from 'rxjs';
import { MainService } from '../../../common/services/main.service';
import { FilterMenu } from '../../../common/services/filterMenu.enum';
import { ProjectFilterViewService } from '../../../common-project/services/project-filter-view.service';
import { ProjectFilterView } from '../../../common-project/models/filters/project-filter-view.model';
import { ProjectFilterViewData } from '../../../common-project/models/filters/project-filter-view-data.model';
import { NotificationService } from '../../../core/services/notification.service';
import { LightweightProjectFilterView } from 'src/common-project/models/filters/lightweight-project-filter-view.model';


@Component({
  selector: 'saved-filters',
  templateUrl: './saved-filters.component.html',
  styleUrls: ['./saved-filters.component.scss']
})
export class SavedFiltersComponent implements OnInit, OnDestroy {
  public isSavingNewFilter: boolean = false;
  @Input() public isMobileDisplay: boolean = false;

  //saved filters menu visibility
  public isSavedFiltersMenuShowing: boolean = false;
  //has someone selected a saved filter - need to show rest of form
  public isSavedFilterSelected: boolean = true;
  private subscriptions = new Array<Subscription>();
  public filterViews: LightweightProjectFilterView[] = [];
  public selectedFilterView: ProjectFilterView = new ProjectFilterView();

  public isFilterViewSaveInProgress: boolean = false;
  public originalDefaultValueForSelectedFilter: boolean = false;

  public get formattedFlagTypeNames(): string {
    return this.selectedFilterView.flagTypes.map(flag => flag.name).join(", ");
  }

  public get formattedOfficeNames(): string {
    return this.selectedFilterView.offices.map(office => office.name).join(", ");
  }

  public get formattedDepartmentNames(): string {
    return this.selectedFilterView.departments.map(dept => dept.name).join(", ");
  }

  public get formattedOwnerClientTypeNames(): string {
    return this.selectedFilterView.ownerClientTypes.map(ownerClientType => ownerClientType.name).join(", ");
  }

  public get formattedPM1DepartmentNames(): string {
    return this.selectedFilterView.projectManager1Departments.map(department => department.name).join(", ");
  }

  public get formattedPM1OfficeNames(): string {
    return this.selectedFilterView.projectManager1Offices.map(office => office.name).join(", ");
  }

  public get formattedPM1EmployeeNames(): string {
    return this.selectedFilterView.projectManager1Employees.map(employee => employee.displayName).join(", ");
  }

  public get formattedPM2EmployeeNames(): string {
    return this.selectedFilterView.projectManager2Employees.map(employee => employee.displayName).join(", ");
  }

  constructor(private _mainService: MainService, protected filterViewService: ProjectFilterViewService, protected notificationService: NotificationService) { }

  ngOnInit(): void {

    //hide menu when click event happens outside of menu
    const uiSubscription1$ = this._mainService.hideMenu.pipe(finalize(() => {
      this.subscriptions.push(uiSubscription1$);
    })).subscribe(() => {
      //hide menu & send update
      this.setMenuVisibility();
    }, error => {
      console.log(error);
    });

    //hide menu when another menu opens
    const uiSubscription2$ = this._mainService.openMenuName.pipe(finalize(() => {
      this.subscriptions.push(uiSubscription2$);
    })).subscribe((value: FilterMenu) => {
      if (value != FilterMenu.Saved) {
        //hide menu - click outside menu
        this.closeMenu(true);
      } else {
        //show menu
        this.closeMenu(false);
      }
      //set variable to show load existing saved filter
      this.isSavingNewFilter = false;
    }, error => {
      console.log(error);
    });

    const allFilterViewsSub = this.filterViewService.getAllFilterViews().pipe(finalize(() => {
      this.subscriptions.push(allFilterViewsSub);
    })).subscribe({next: (result: ProjectFilterViewData) => {
      this.filterViews = result.filterViews;
      if (result.defaultFilterView) {
        this.selectedFilterView = result.defaultFilterView;
        this.originalDefaultValueForSelectedFilter = true;
        this.applyFilterView();
      }

    }});

    this.filterViewService.onFilterViewSaved.subscribe((savedFilterView: LightweightProjectFilterView) => {
      this.filterViews.push(savedFilterView);
      this.filterViews.sort((a, b) => (a.filterViewName < b.filterViewName ? -1 : 1));
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public applyFilterView(): void {
    if (this.selectedFilterView != null) {
      let lightweightSelectedFilterView = this.filterViews.find(x => x.filterViewKey == this.selectedFilterView.filterViewKey);
      if (lightweightSelectedFilterView != null) {
        lightweightSelectedFilterView = new LightweightProjectFilterView().mapTo(this.selectedFilterView);
        this.filterViewService.filterViewApplied$.next(lightweightSelectedFilterView);
        this._mainService.isFilterApplied.next(true);
      } else {
        this._mainService.isFilterApplied.next(false);
      }
      if (this.selectedFilterView.isDefault != this.originalDefaultValueForSelectedFilter ) {
        const updateDefaultFilterViewSub = this.updateDefaultFilterView().subscribe({next: (result: any) => {
          this.subscriptions.push(updateDefaultFilterViewSub);
          this.toggleMenuVisibility(true);
        },
        error: (error: any) => {
          this.toggleMenuVisibility(true);
        }});
      }

    } else {
      this.toggleMenuVisibility(true);
    }
  }

  public toggleMenu(e: Event) {
    e.stopPropagation();
    this.isSavedFiltersMenuShowing = !this.isSavedFiltersMenuShowing;
    this.setMenuVisibility();
  }

  public deleteFilter(): void {
    if (this.selectedFilterView?.filterViewKey != null) {
      this.isFilterViewSaveInProgress = true;
      const deleteFilterViewSub = this.filterViewService.deleteFilterView(this.selectedFilterView.filterViewKey).pipe(finalize(() => {
        this.subscriptions.push(deleteFilterViewSub);
      })).subscribe({next: () => {
        this.filterViews = this.filterViews.filter(x => x.filterViewKey != this.selectedFilterView?.filterViewKey);
        this.selectedFilterView.filterViewKey = null;
        this.toggleMenuVisibility(true);
        this.isFilterViewSaveInProgress = false;

        this.notificationService.showSuccess("Filter view has been deleted");
      },
      error: () => {
        this.isFilterViewSaveInProgress = false;
        this.notificationService.showError("Deletion of filter view failed due to an unexpected error.  Please refresh the page and try again.");
      }});
    }

    this.toggleMenuVisibility(true);
  }

  public toggleMenuVisibility(closeMenu: boolean): void {
    this.closeMenu(closeMenu);
    this.setMenuVisibility();
  }

  public updateDefaultFilterView(): Observable<void> {
    const updateDefaultObservable = new Observable<void>((observer: any) => {
      if (this.selectedFilterView.filterViewKey != null) {
        this.isFilterViewSaveInProgress = true;
        const updateDefaultFilterViewSub = this.filterViewService.updateDefaultFilterView(this.selectedFilterView.filterViewKey, this.selectedFilterView.isDefault).pipe(finalize(() => {
          this.subscriptions.push(updateDefaultFilterViewSub);
        })).subscribe({next: () => {
          this.filterViews.filter(x => x.filterViewKey != this.selectedFilterView.filterViewKey).forEach(x => x.isDefault = false);
          this.originalDefaultValueForSelectedFilter = this.selectedFilterView.isDefault;

          this.isFilterViewSaveInProgress = false;
          observer.next();

          this.notificationService.showSuccess("Default filter view has been updated");
        },
        error: () => {
          observer.error();
          this.isFilterViewSaveInProgress = false;
          this.notificationService.showError("Unable to update your default filter view.  Please refresh the page and try again.");
        }});
      }
    });
    return updateDefaultObservable;
  }


  private closeMenu(close:boolean): void {
    this.isSavedFiltersMenuShowing = !close;
  }

  private setMenuVisibility(): void {
    if (this.isSavedFiltersMenuShowing) {
      //set service variable for menu shown
      this._mainService.setIsMenuVisible(FilterMenu.Saved);
    } else {
      this._mainService.setIsMenuVisible(FilterMenu.None);
    }
  }

  public setSelectedFilterView(selectedFilterViewKey: string): void {
    this.isSavedFilterSelected = true;

    if (selectedFilterViewKey != null && selectedFilterViewKey != "-1") {
      const getFilterViewSub = this.filterViewService.getFilterView(selectedFilterViewKey).pipe(finalize(() => {
        this.subscriptions.push(getFilterViewSub);
      })).subscribe({next: (result: ProjectFilterView) => {
        this.selectedFilterView = result;
        this.originalDefaultValueForSelectedFilter = this.selectedFilterView.isDefault;
      }});
    } else {
      this.originalDefaultValueForSelectedFilter = false;
    }
  }
}
