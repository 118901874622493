import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs';

@Component({
  selector: 'login-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

    public baseServiceUrl: string = environment.baseServiceUrl;

    constructor(protected authService: AuthService, protected router: Router, 
        protected msalAuthService: MsalService, protected msalBroadcastService: MsalBroadcastService) { }

    public async ngOnInit(): Promise<void> {
        await this.authService.getUserInfo(true).subscribe({next: () => {
            if (this.authService.appUser != null && this.authService.appUser.username != null) {
                this.router.navigate(['/projects']);
            }
            this.msalBroadcastService.msalSubject$
                .pipe(filter((message: EventMessage) => message.eventType === EventType.LOGIN_SUCCESS))
                .subscribe((result: EventMessage) => {
                    this.router.navigate(['/projects']);
                    console.log(result);
                });
        }});
    }

    public login(): void {
        this.authService.login();
    }
}
