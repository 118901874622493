import { DepartmentLookup } from "../lookups/department-lookup.model";
import { FlagLookup } from "../lookups/flag-lookup.model";
import { OfficeLookup } from "../lookups/office-lookup.model";
import { OwnerClientTypeLookup } from "../lookups/owner-client-type-lookup.model";
import { ProjectManager1DepartmentLookup } from "../lookups/project-manager1-department-lookup.model";
import { ProjectManagerEmployeeLookup } from "../lookups/project-manager-employee-lookup.model";
import { ProjectManager1OfficeLookup } from "../lookups/project-manager1-office-lookup.model";

export class ProjectFilterView {
    public filterViewKey!: string | null;
    public filterViewName!: string;
    public flagTypes: FlagLookup[] = [];
    public offices: OfficeLookup[] = [];
    public departments: DepartmentLookup[] = [];
    public ownerClientTypes: OwnerClientTypeLookup[] = [];
    public isDesignBuild: boolean = false;
    public isActiveProjectStatus: boolean = true;
    public isCompletedProjectStatus: boolean = false;
    public isDefault: boolean = false;
    public projectManager1Departments: ProjectManager1DepartmentLookup[] = [];
    public projectManager1Offices: ProjectManager1OfficeLookup[] = [];
    public projectManager1Employees: ProjectManagerEmployeeLookup[] = [];
    public projectManager2Employees: ProjectManagerEmployeeLookup[] = [];
}