import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../../core/services/auth.service";

@Injectable()
export class ProjectHeaderMetadataInterceptor implements HttpInterceptor {
  constructor(protected authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let projectKey: string | null = null;
    if (request.method == 'GET') {
        const matchedProjectKey = request.url.match("(?<=project/)\\d+");
        if (matchedProjectKey && matchedProjectKey.length) {
            projectKey = matchedProjectKey[0];
        }
    } else {
        projectKey = request.body?.projectKey;
    }
    if (projectKey != null) {
        request = request.clone({
            setHeaders: { ProjectKey: projectKey.toString(), TempUserPermissions: JSON.stringify(this.authService.userRoleTypes) }
        });
    } else {
        request = request.clone({
            setHeaders: { TempUserPermissions: JSON.stringify(this.authService.userRoleTypes) }
        });
    }
    return next.handle(request);
  }
}