import { Injectable } from "@angular/core";
import { finalize, Observable, Subject } from "rxjs";
import { SINGLETON_INJECTABLE_TYPE } from "../../common/models/constants.model";
import { PendingQueueRequest } from "../models/pending-queued-request.model";
import { Queue } from "../models/queue.model";

@Injectable({
    providedIn: SINGLETON_INJECTABLE_TYPE
  })
export class HttpQueueService {
    private queue: Queue<PendingQueueRequest<any>> = new Queue();
    private _retriesOnError: number = 0;

    public execute<T>(request: Observable<T>): Observable<T> {
        const result = this.enqueueHttpRequest<T>(request); 
        return result;
    }

    public get hasPendingRequests(): boolean {
        return this.queue.queueLength > 0;
    }

    private enqueueHttpRequest<T>(request: Observable<T>): Observable<T> {
        const requestSubject = new Subject<T>();
        const pendingQueueRequest = new PendingQueueRequest(request, requestSubject);

        this.queue.enqueue(pendingQueueRequest);

        if (this.queue.queueLength === 1) {
            this.executeNextRequest();
        }

        return requestSubject.asObservable();
    }

    private executeNextRequest(): void {
        if (this.queue.queueLength > 0 || this.queue.poisonMessageQueueLength > 0){ 
            const pendingRequest = this.queue.poisonMessageQueueLength > 0 ? this.queue.dequeuePoisonMessageQueue() : this.queue.dequeue();

            const requestSub = pendingRequest?.request.pipe(
                finalize(() => {
                    this.executeNextRequest();
                    requestSub?.unsubscribe();
                })
            ).subscribe({
                next: (result: any) => {
                    pendingRequest.subscription.next(result);
                },
                error: (error: any) => {
                    this.queue.enqueueToPoisonMessageQueue(pendingRequest);
                    
                    if (this._retriesOnError > 3) {
                        this._retriesOnError = 0;

                        //TODO: Likely want to change how errors are handled.  May not want to clear out all pending requests in both queues.
                        for(var i = 0; i < this.queue.poisonMessageQueueLength; i++) {
                            const poisonMessage = this.queue.dequeuePoisonMessageQueue();
                            poisonMessage?.subscription.unsubscribe();
                        }

                        for (var i = 0; i < this.queue.queueLength; i++) {
                            const queueMessage = this.queue.dequeue();
                            queueMessage?.subscription.unsubscribe();
                        }

                        pendingRequest.subscription.error(error);

                    } else {
                        this._retriesOnError++;

                    }  
                },
                complete: () => {
                    pendingRequest.subscription.complete();
                }
            });
        }
    }
}