import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SINGLETON_INJECTABLE_TYPE } from '../../common/models/constants.model';
import { environment } from '../../environments/environment';
import { ProjectFilterLookupContainer } from '../models/lookups/project-filter-lookup-container.model';
import { AdditionalFiltersModel } from '../models/filters/additional-filters-model.model';

@Injectable({
  providedIn: SINGLETON_INJECTABLE_TYPE,
})
export class ProjectFilterService {

  public projectFilterApplied: Subject<AdditionalFiltersModel> = new Subject();
  public onProjectFilterApplied: Observable<AdditionalFiltersModel> = this.projectFilterApplied.asObservable();
  private myProjectsOnly$ = new BehaviorSubject<boolean>(false);
  public onMyProjectsOnly: Observable<boolean> = this.myProjectsOnly$.asObservable();

  private needsReviewedProjects$ = new BehaviorSubject<boolean>(false);
  public onNeedsReviewedProjects: Observable<boolean> = this.needsReviewedProjects$.asObservable();

  private needsUpdatedProjects$ = new BehaviorSubject<boolean>(false);
  public onNeedsUpdatedProjects: Observable<boolean> = this.needsUpdatedProjects$.asObservable();

  public set setMyProjectsOnly(value: boolean) {
    this.myProjectsOnly$.next(value);
  }

  public get getMyProjectsOnly(): Observable<boolean> {
    return this.myProjectsOnly$.asObservable();
  }

  public set setNeedsReviewedProjects(value: boolean) {
    this.needsReviewedProjects$.next(value);
  }

  public set setNeedsUpdatedProjects(value: boolean) {
    this.needsUpdatedProjects$.next(value);
  }

  public get getNeedsReviewedProjects(): Observable<boolean> {
    return this.needsReviewedProjects$.asObservable();
  }

  public get getNeedsUpdatedProjects(): Observable<boolean> {
    return this.needsUpdatedProjects$.asObservable();
  }

  constructor(
    public http: HttpClient
  ) {
  }

  public getLookups(): Observable<ProjectFilterLookupContainer> {
    return this.http.get<ProjectFilterLookupContainer>(`${environment.baseServiceUrl}/api/projectFilter/lookups`);
  }


}
