import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, map, Observable, startWith, Subscription } from 'rxjs';
import { AdditionalFiltersModel } from '../../models/filters/additional-filters-model.model';
import { FlagLookup } from 'src/common-project/models/lookups/flag-lookup.model';
import { ProjectFilterLookupContainer } from 'src/common-project/models/lookups/project-filter-lookup-container.model';
import { ProjectFilterService } from '../../../common-project/services/project-filter.service';
import { MainService } from '../../../common/services/main.service';
import { FilterMenu } from '../../../common/services/filterMenu.enum';
import { OfficeLookup } from 'src/common-project/models/lookups/office-lookup.model';
import { DepartmentLookup } from 'src/common-project/models/lookups/department-lookup.model';
import { OwnerClientTypeLookup } from 'src/common-project/models/lookups/owner-client-type-lookup.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ProjectFilterViewService } from '../../../common-project/services/project-filter-view.service';
import { ProjectFilterView } from '../../../common-project/models/filters/project-filter-view.model';
import { LightweightProjectFilterView } from '../../../common-project/models/filters/lightweight-project-filter-view.model';
import { NotificationService } from '../../../core/services/notification.service';
import { ProjectManager1DepartmentLookup } from '../../../common-project/models/lookups/project-manager1-department-lookup.model';
import { ProjectManager1OfficeLookup } from '../../../common-project/models/lookups/project-manager1-office-lookup.model';
import { ProjectManagerEmployeeLookup } from '../../models/lookups/project-manager-employee-lookup.model';


interface SelectItem {
  value: string;
  viewValue: string;
}

@Component({
  selector: '[additional-filters]',
  templateUrl: './additional-filters.component.html',
  styleUrls: ['./additional-filters.component.scss']
})
export class AdditionalFiltersComponent implements OnInit, OnDestroy {
  //main "saved" filter
  @Input()
  public isFilterApplied: boolean = false;
  //is mobile display for saving/calling filters
  @Input()
  public isMobileFilterDisplay: boolean = false;

  public isSavingNewFilter: boolean = false;

  //apply filters menu visibility
  public isAppliedFiltersMenuShowing: boolean = false;

  public isNewFilterViewSaveInProgress: boolean = false;

  public additionalFiltersForm!: FormGroup;
  public filterLookups: ProjectFilterLookupContainer = new ProjectFilterLookupContainer();

  public filteredFlags: Observable<FlagLookup[]> = new Observable<FlagLookup[]>();
  public filteredOffices: Observable<OfficeLookup[]> = new Observable<OfficeLookup[]>();
  public filteredDepartments: Observable<DepartmentLookup[]> = new Observable<DepartmentLookup[]>();
  public filteredOwnerClientTypes: Observable<OwnerClientTypeLookup[]> = new Observable<OwnerClientTypeLookup[]>();
  public filteredProjectManager1Departments: Observable<ProjectManager1DepartmentLookup[]> = new Observable<ProjectManager1DepartmentLookup[]>();
  public filteredProjectManager1Offices: Observable<ProjectManager1OfficeLookup[]> = new Observable<ProjectManager1OfficeLookup[]>();
  public filteredProjectManager1Employees: Observable<ProjectManagerEmployeeLookup[]> = new Observable<ProjectManagerEmployeeLookup[]>();
  public filteredProjectManager2Employees: Observable<ProjectManagerEmployeeLookup[]> = new Observable<ProjectManagerEmployeeLookup[]>();

  private subscriptions = new Array<Subscription>();

  public readonly FLAG_TYPE_FORM_CONTROL_NAME = 'flagType';
  public readonly DEPARTMENT_FORM_CONTROL_NAME = 'department';
  public readonly OFFICE_FORM_CONTROL_NAME = 'office';
  public readonly OWNER_CLIENT_TYPE_FORM_CONTROL_NAME = 'ownerClientType';
  public readonly NEW_FILTER_VIEW_NAME_FORM_CONTROL_NAME = 'newFilterViewName';
  public readonly PM1_DEPARTMENT_FORM_CONTROL_NAME = 'pm1Department';
  public readonly PM1_OFFICE_FORM_CONTROL_NAME = 'pm1Office';
  public readonly PM1_EMPLOYEE_FORM_CONTROL_NAME = 'pm1Employee';
  public readonly PM2_EMPLOYEE_FORM_CONTROL_NAME = 'pm2Employee';

  public selectedFilters: AdditionalFiltersModel = new AdditionalFiltersModel();
  public myProjectsOnly: boolean = false;
  public isNeedsReviewed: boolean = false;
  public isNeedsUpdated: boolean = false;

  @ViewChild('flagType', { static: false }) flagType!: NgSelectComponent;
  @ViewChild('office', { static: false }) office!: NgSelectComponent;
  @ViewChild('department', { static: false }) department!: NgSelectComponent;
  @ViewChild('owner', { static: false }) owner!: NgSelectComponent;
  @ViewChild('pm1Department', { static: false }) pm1Department!: NgSelectComponent;
  @ViewChild('pm1Office', { static: false }) pm1Office!: NgSelectComponent;
  @ViewChild('savedFilterName', { static: false }) savedFilterName!: ElementRef;
  @ViewChild('pm1Employee', { static: false }) pm1Employee!: NgSelectComponent;
  @ViewChild('pm2Employee', { static: false }) pm2Employee!: NgSelectComponent;

  constructor(private changeDetectorRef: ChangeDetectorRef, protected mainService: MainService, private formBuilder: FormBuilder, protected filterService: ProjectFilterService,
              protected filterViewService: ProjectFilterViewService, protected notificationService: NotificationService) { }

  public ngOnInit(): void {
    const uiSubscription$ = this.mainService.onProjectDetailsDisplayChanged.pipe(finalize(() => {
        this.subscriptions.push(uiSubscription$);
      })).subscribe((isOpen: boolean) => {
        //send is mobile display to saved-filters
        this.mainService.setMobileProjectFilterDisplay();
      }, error => {
        console.log(error);
      });

      //hide menu when click event happens outside of menu
      const uiSubscription2$ = this.mainService.hideMenu.pipe(finalize(() => {
        this.subscriptions.push(uiSubscription2$);
      })).subscribe(() => {
        //hide menu &
        //set variable for update
        this.closeMenuUpdateServiceVariable();
      }, error => {
        console.log(error);
      });

    //hide menu when another menu opens
    const uiSubscription3$ = this.mainService.openMenuName.pipe(finalize(() => {
      this.subscriptions.push(uiSubscription3$);
    })).subscribe((value: FilterMenu) => {
      if (value != FilterMenu.Applied) {
        //hide menu
        this.closeMenu();
      }

    }, error => {
      console.log(error);
    });

    const myProjects$ = this.filterService.getMyProjectsOnly.subscribe((result: boolean) => {
      this.selectedFilters.myProjectsOnly = this.myProjectsOnly = result;
      this.filterService.projectFilterApplied.next(this.selectedFilters);
    });
    this.subscriptions.push(myProjects$);
      this.additionalFiltersForm = this.formBuilder.group({
        flagType: [[]],
        office: [[]],
        department: [[]],
        ownerClientType: [[]],
        newFilterViewName: null,
        pm1Department: [[]],
        pm1Office: [[]],
        pm1Employee: [[]],
        pm2Employee: [[]]
      });

      const needsReviewedProjects$ = this.filterService.getNeedsReviewedProjects.pipe(finalize(() => {
        this.subscriptions.push(needsReviewedProjects$);
      })).subscribe((result: boolean) => {
        this.selectedFilters.isNeedsReviewed = this.isNeedsReviewed = result;
        this.filterService.projectFilterApplied.next(this.selectedFilters);
      });

      const needsUpdatedProjects$ = this.filterService.getNeedsUpdatedProjects.pipe(finalize(() => {
        this.subscriptions.push(needsUpdatedProjects$);
      })).subscribe((result: boolean) => {
        this.selectedFilters.isNeedsUpdated = this.isNeedsUpdated = result;
        this.filterService.projectFilterApplied.next(this.selectedFilters);
      });

      if (!this.filterLookups.departments.length || !this.filterLookups.flags.length || !this.filterLookups.offices.length || !this.filterLookups.ownerClientTypes.length) {
        let filterLookupsSubscription = this.filterService.getLookups().subscribe({
          next: (result: ProjectFilterLookupContainer) => {
            this.filterLookups = result;
            this.subscriptions.push(filterLookupsSubscription);
            this.filterAdditionalFilters();
          },
          error: (error: any) => {
            console.log(error);
          }
        });
      } else {
        this.filterAdditionalFilters();
      }

      const onFilterViewAppliedSub = this.filterViewService.onFilterViewApplied.pipe(finalize(() => {
        this.subscriptions.push(onFilterViewAppliedSub);
      })).subscribe({
        next: (result: LightweightProjectFilterView) => {
          this.additionalFiltersForm.get(this.FLAG_TYPE_FORM_CONTROL_NAME)?.setValue(result.flagTypeIds);
          this.additionalFiltersForm.get(this.DEPARTMENT_FORM_CONTROL_NAME)?.setValue(result.departmentIds);
          this.additionalFiltersForm.get(this.OFFICE_FORM_CONTROL_NAME)?.setValue(result.officeIds);
          this.additionalFiltersForm.get(this.OWNER_CLIENT_TYPE_FORM_CONTROL_NAME)?.setValue(result.ownerClientTypeIds);
          this.additionalFiltersForm.get(this.PM1_DEPARTMENT_FORM_CONTROL_NAME)?.setValue(result.projectManager1DepartmentCodes);
          this.additionalFiltersForm.get(this.PM1_OFFICE_FORM_CONTROL_NAME)?.setValue(result.projectManager1OfficeCodes);
          this.additionalFiltersForm.get(this.PM1_EMPLOYEE_FORM_CONTROL_NAME)?.setValue(result.projectManager1EmployeeNumbers);
          this.additionalFiltersForm.get(this.PM2_EMPLOYEE_FORM_CONTROL_NAME)?.setValue(result.projectManager2EmployeeNumbers);

          this.selectedFilters.departmentIds = result.departmentIds;
          this.selectedFilters.flagIds = result.flagTypeIds;
          this.selectedFilters.officeIds = result.officeIds;
          this.selectedFilters.ownerClientTypeIds = result.ownerClientTypeIds;
          this.selectedFilters.includeActive = result.isActiveProjectStatus;
          this.selectedFilters.includeCompleted = result.isCompletedProjectStatus;
          this.selectedFilters.isDesignBuild = result.isDesignBuild;
          this.selectedFilters.projectManager1DepartmentCodes = result.projectManager1DepartmentCodes;
          this.selectedFilters.projectManager1OfficeCodes = result.projectManager1OfficeCodes;
          this.selectedFilters.projectManager1EmployeeNumbers = result.projectManager1EmployeeNumbers;
          this.selectedFilters.projectManager2EmployeeNumbers = result.projectManager2EmployeeNumbers;

          this.setFiltersApplied(true);
        },
        error: (error: any) => {
          console.log(error);
        }
      });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }


  public setFiltersApplied(isApplied: boolean): void {
    this.isFilterApplied = isApplied;
    this.mainService.isFilterApplied.next(isApplied);

    if (!isApplied) {
      this.selectedFilters = new AdditionalFiltersModel();
      this.selectedFilters.flagIds = [];
      this.selectedFilters.departmentIds = [];
      this.selectedFilters.officeIds = [];
      this.selectedFilters.ownerClientTypeIds = [];
      this.selectedFilters.myProjectsOnly = this.myProjectsOnly;
      this.selectedFilters.projectManager1DepartmentCodes = [];
      this.selectedFilters.projectManager1OfficeCodes = [];
      this.selectedFilters.projectManager1EmployeeNumbers = [];
      this.selectedFilters.projectManager2EmployeeNumbers = [];
      this.selectedFilters.isNeedsReviewed = this.isNeedsReviewed;

      this.additionalFiltersForm.reset();
    } else if (!this.selectedFilters.departmentIds.length && !this.selectedFilters.flagIds.length
              && !this.selectedFilters.officeIds.length && !this.selectedFilters.ownerClientTypeIds.length
              && !this.selectedFilters.isDesignBuild && !this.selectedFilters.projectManager1DepartmentCodes.length
              && !this.selectedFilters.projectManager1OfficeCodes.length && !this.selectedFilters.projectManager1EmployeeNumbers.length
              && !this.selectedFilters.projectManager2EmployeeNumbers.length) {
        isApplied = false;
    }
    this.selectedFilters.isCleared = !isApplied;

    this.filterService.projectFilterApplied.next(this.selectedFilters);
    this.closeMenuUpdateServiceVariable();
  }

  public toggleMenu(e: Event) {
    e.stopPropagation();
    this.isAppliedFiltersMenuShowing = !this.isAppliedFiltersMenuShowing;

    //required for *ngIf
    this.changeDetectorRef.detectChanges();

    if (this.isAppliedFiltersMenuShowing == true) {
      this.flagType.focus();
    }
    this.setMenuVisibility();
  }

  public openFlagType(): void {
    this.flagType.open();
  }

  public openOffice(): void {
    this.office.open();
  }

  public openDepartment(): void {
    this.department.open();
  }

  public openOwner(): void {
    this.owner.open();
  }

  public openPM1Department(): void {
    this.pm1Department.open();
  }

  public openPM1Office(): void {
    this.pm1Office.open();
  }

  public openPM1Employee(): void {
    this.pm1Employee.open();
  }

  public openPM2Employee(): void {
    this.pm2Employee.open();
  }

  public filterAdditionalFilters() {
    this.filteredFlags = this.additionalFiltersForm.controls[this.FLAG_TYPE_FORM_CONTROL_NAME]?.valueChanges
      .pipe(startWith(''),
        map(() => {
          return this.filterLookups.flags.filter(x => x.name.toLowerCase());
        }
      ));
    this.filteredDepartments = this.additionalFiltersForm.controls[this.DEPARTMENT_FORM_CONTROL_NAME]?.valueChanges
      .pipe(startWith(''),
        map(() => {
          return this.filterLookups.departments.filter(x => x.name.toLowerCase());
        }
      ));
    this.filteredOffices = this.additionalFiltersForm.controls[this.OFFICE_FORM_CONTROL_NAME]?.valueChanges
      .pipe(startWith(''),
        map(() => {
          return this.filterLookups.offices.filter(x => x.name.toLowerCase());
        }
      ));
    this.filteredOwnerClientTypes = this.additionalFiltersForm.controls[this.OWNER_CLIENT_TYPE_FORM_CONTROL_NAME]?.valueChanges
      .pipe(startWith(''),
        map(() => {
          return this.filterLookups.ownerClientTypes.filter(x => x.name.toLowerCase());
        }
      ));
    this.filteredProjectManager1Departments = this.additionalFiltersForm.controls[this.PM1_DEPARTMENT_FORM_CONTROL_NAME]?.valueChanges
    .pipe(startWith(''),
      map(() => {
        return this.filterLookups.projectManager1Departments.filter(x => x.name.toLowerCase());
      }
    ));
    this.filteredProjectManager1Offices = this.additionalFiltersForm.controls[this.PM1_OFFICE_FORM_CONTROL_NAME]?.valueChanges
    .pipe(startWith(''),
      map(() => {
        return this.filterLookups.projectManager1Offices.filter(x => x.name.toLowerCase());
      }
    ));
    this.filteredProjectManager1Employees = this.additionalFiltersForm.controls[this.PM1_EMPLOYEE_FORM_CONTROL_NAME]?.valueChanges
    .pipe(startWith(''),
      map(() => {
        return this.filterLookups.projectManager1Employees.filter(x => x.displayName.toLowerCase());
      }
    ));
    this.filteredProjectManager2Employees = this.additionalFiltersForm.controls[this.PM2_EMPLOYEE_FORM_CONTROL_NAME]?.valueChanges
    .pipe(startWith(''),
      map(() => {
        return this.filterLookups.projectManager2Employees.filter(x => x.displayName.toLowerCase());
      }
    ));
  }

  public setFilterSelection(ids: [], controlName: string) {
    switch (controlName) {
      case this.OWNER_CLIENT_TYPE_FORM_CONTROL_NAME:
        this.selectedFilters.ownerClientTypeIds = ids;
        break;
      case this.DEPARTMENT_FORM_CONTROL_NAME:
        this.selectedFilters.departmentIds = ids;
        break;
      case this.OFFICE_FORM_CONTROL_NAME:
        this.selectedFilters.officeIds = ids;
        break;
      case this.FLAG_TYPE_FORM_CONTROL_NAME:
        this.selectedFilters.flagIds = ids;
        break;
      case this.PM1_DEPARTMENT_FORM_CONTROL_NAME:
        this.selectedFilters.projectManager1DepartmentCodes = ids;
        break;
      case this.PM1_OFFICE_FORM_CONTROL_NAME:
        this.selectedFilters.projectManager1OfficeCodes = ids;
        break;
      case this.PM1_EMPLOYEE_FORM_CONTROL_NAME:
        this.selectedFilters.projectManager1EmployeeNumbers = ids;
        break;
      case this.PM2_EMPLOYEE_FORM_CONTROL_NAME:
        this.selectedFilters.projectManager2EmployeeNumbers = ids;
        break;
    }
  }

  public closeMenuUpdateServiceVariable(): void {
    this.closeMenu();
    this.setMenuVisibility();
  }

  private closeMenu(): void {
    this.isAppliedFiltersMenuShowing = false;
    this.additionalFiltersForm.get(this.NEW_FILTER_VIEW_NAME_FORM_CONTROL_NAME)?.setValidators(null);
  }

  private setMenuVisibility(): void {
    if (this.isAppliedFiltersMenuShowing == true) {
      //set service variable for menu shown
      this.mainService.setIsMenuVisible(FilterMenu.Applied);
    } else {
      this.mainService.setIsMenuVisible(FilterMenu.None);
    }
  }

  public setIsSavingNewFilter(e: Event): void {
    //stop from bubbling and firing more menu close events!
    e.stopPropagation();

    this.additionalFiltersForm.get(this.NEW_FILTER_VIEW_NAME_FORM_CONTROL_NAME)?.setValidators([Validators.required]);

    if (!this.savedFilterName) {
      //show saved filter name textbox
      this.isSavingNewFilter = true;
    }

    if (this.savedFilterName !== null && this.additionalFiltersForm.get(this.NEW_FILTER_VIEW_NAME_FORM_CONTROL_NAME)?.value != null) {
      let newFilterView: LightweightProjectFilterView = new LightweightProjectFilterView();
      newFilterView.departmentIds = this.selectedFilters.departmentIds;
      newFilterView.flagTypeIds = this.selectedFilters.flagIds;
      newFilterView.officeIds = this.selectedFilters.officeIds;
      newFilterView.ownerClientTypeIds = this.selectedFilters.ownerClientTypeIds;
      newFilterView.filterViewName = this.additionalFiltersForm.get(this.NEW_FILTER_VIEW_NAME_FORM_CONTROL_NAME)?.value;
      newFilterView.isActiveProjectStatus = this.selectedFilters.includeActive;
      newFilterView.isCompletedProjectStatus = this.selectedFilters.includeCompleted;
      newFilterView.isDesignBuild = this.selectedFilters.isDesignBuild;
      newFilterView.projectManager1DepartmentCodes = this.selectedFilters.projectManager1DepartmentCodes;
      newFilterView.projectManager1OfficeCodes = this.selectedFilters.projectManager1OfficeCodes;
      newFilterView.projectManager1EmployeeNumbers = this.selectedFilters.projectManager1EmployeeNumbers;
      newFilterView.projectManager2EmployeeNumbers = this.selectedFilters.projectManager2EmployeeNumbers;

      this.isNewFilterViewSaveInProgress = true;

      const createFilterViewSub = this.filterViewService.createFilterView(newFilterView).pipe(finalize(() => {
        this.subscriptions.push(createFilterViewSub);
      })).subscribe({next: (result: LightweightProjectFilterView) => {
        this.filterViewService.filterViewSaved$.next(result);
        this.closeMenu();
        this.setMenuVisibility();
        this.isNewFilterViewSaveInProgress = false;
        this.additionalFiltersForm.get(this.NEW_FILTER_VIEW_NAME_FORM_CONTROL_NAME)?.setValue(null);

        this.notificationService.showSuccess("Filter view has been created")
      },
      error: (error: any) => {
        this.closeMenu();
        this.setMenuVisibility();
        this.isNewFilterViewSaveInProgress = false;
        this.additionalFiltersForm.get(this.NEW_FILTER_VIEW_NAME_FORM_CONTROL_NAME)?.setValue(null);

        if (error.status === 400 && error.error != null) {
          this.notificationService.showError(`${error.error}`);
        } else {
          this.notificationService.showError("Creation of filter view failed due to an unexpected error.  Please refresh the page and try again.");
        }

      }});

      //hide saved filter name textbox
      this.isSavingNewFilter = false;
    }
  }
}
