import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ProjectService } from 'src/projects/services/project.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgGridLibraryModule } from 'jmt_linked_node_modules/ag-grid-library';
import { CommonModule} from '@angular/common';
import { ProjectPortfolioCommonModule } from '../common/common.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { BannerComponent } from './components/banner/banner.component';
import { ProjectPortfolioCoreModule } from '../core/core.module';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, ProtectedResourceScopes } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, protectedResources } from './msal-config';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './components/footer/footer.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { LoaderService } from 'src/common/services/loader.service';
import { CommonProjectModule } from '../common-project/common-project.module';
import { FormsModule } from '@angular/forms';
import { UserInfoResolver } from './user-info.resolver';
import { googleAnalyticsInitializer } from './initializers/google-analytics.initializer';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const authentication = { 
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: [...protectedResources.api.scopes.read] }
  };
  return authentication as MsalGuardConfiguration;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(`${environment.baseServiceUrl}/*`, [{ httpMethod: 'GET', scopes: [...protectedResources.api.scopes.read] },
                                                            { httpMethod: 'DELETE', scopes: [...protectedResources.api.scopes.read] },
                                                            { httpMethod: 'PUT', scopes: [...protectedResources.api.scopes.read] },
                                                            {httpMethod: 'POST', scopes: [...protectedResources.api.scopes.read]}]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    IconSpriteModule.forRoot({ path: './assets/sprites/sprite.svg' }),
    AgGridLibraryModule,
    ProjectPortfolioCommonModule,
    ProjectPortfolioCoreModule,
    BrowserAnimationsModule,
    NgSelectModule,
    CommonProjectModule,
    FormsModule,
    MsalModule,
  ],

  providers: [ProjectService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    //NOTE: There likely is an issue with the msal interceptor, can cause redirect back to login screen so effectively need to login twice after logging off.
    // We likely will need to create our own interceptor instead and add the token to authorization header ourselves.
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: APP_INITIALIZER,
      useFactory: googleAnalyticsInitializer,
      deps: [GoogleAnalyticsService],
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    LoaderService,
    UserInfoResolver,
    GoogleAnalyticsService
],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
