import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApplicationUserDto } from '../../../core/models/application-user.dto.model';
import { AuthService } from '../../../core/services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
  public environment: string = environment.displayName;
  public baseServiceUrl: string = environment.baseServiceUrl;
  subscription = new Subscription();
  public authenticatedUser: ApplicationUserDto | null = null;
  constructor(
    protected authService: AuthService
    ) { }

  public ngOnInit(): void {
    this.subscription = this.authService.onAuthenticated.subscribe(() => {
      this.authenticatedUser = this.authService.appUser;
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  public login(): void {
    this.authService.login();
  }

  public logOff(): void {
    this.authService.logoff();
  }
}
