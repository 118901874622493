import { Component, Input, ElementRef, Renderer2, OnInit, ViewChild, AfterViewInit, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'static-loader',
  templateUrl: './static-loader.component.html',
  styleUrls: ['./static-loader.component.scss'],
})
export class StaticLoaderComponent implements OnInit, AfterViewInit {
  private _diameter!: number;

  @ViewChild('loader', { read: ElementRef, static: true })
  public loaderElement!: ElementRef;
  @ViewChildren('textElement', { read: ElementRef })
  public textElement: QueryList<ElementRef> = new QueryList<ElementRef>();
  @Input()
  textColor: string = 'black';
  @Input()
  public text?: string | null = "";
  @Input()
  set diameter(val: number) {
    this._diameter = val;

    if (!this.loaderElement)
      return;

    let div = this.loaderElement.nativeElement;
    this._renderer2.setStyle(div, 'width', `${this._diameter}px`);
    this._renderer2.setStyle(div, 'height', `${this._diameter}px`);
  }

  get diameter() {
    return this._diameter
  }

  constructor(
    private _renderer2: Renderer2
  ) { }

  public ngOnInit() {
    if (!this.diameter)
      this.diameter = 80;
  }

  public ngAfterViewInit() {
    this.diameter = this._diameter;
  }
}
