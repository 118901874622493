import { Pipe, PipeTransform } from "@angular/core";

/**
 * @description
 * Order by for an object using the property provided and in direction (ASC or DESC)
 * 
 * @param {Array<any>} value - array of objects to sort a property by
 * @param {string} propertyName - The property name for array of objects to order by
 * @param {string} orderByDirection - The order by direction to sort the objects by -- default is descending order
 **/
@Pipe({ name: "orderByProperty" })
export class OrderByPropertyPipe implements PipeTransform {
  
  public transform(value: Array<any>, propertyName: string, orderByDirection: string = 'desc') {
    if (value == null || value == undefined || !Array.isArray(value)) {
      return;
    }

    value.sort((object1: any, object2: any) => {
      if (orderByDirection == 'desc') {
        if (object1[propertyName] > object2[propertyName]) {
          return -1;
        } else if (object1[propertyName] < object2[propertyName]) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (object1[propertyName] < object2[propertyName]) {
          return -1;
        } else if (object1[propertyName] > object2[propertyName]) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    return value;
  };
}
