import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoaderComponent } from '../components/loader/loader.component';
import { SINGLETON_INJECTABLE_TYPE } from '../models/constants.model';

import { Loader, ObservableLoader } from "../models/loader.model";

@Injectable({
  providedIn: SINGLETON_INJECTABLE_TYPE,
})
export class LoaderService {
  showLoader: boolean = false;
  public _loader!: LoaderComponent;
  public loader = new Subject<Loader>();

  public _randomTexts: string[] = [
    ` `
  ];

  public queueObservables(items: ObservableLoader[], simCity: boolean = true): Observable<any> {
    if (simCity) {
      items.forEach(x => {
        if (!x.text)
          x.text = this._getRandomText();
      });
    }

    return this._loader.load(items);
  }

  public _getRandomText() {
    let random = Math.floor(Math.random() * this._randomTexts.length);

    return this._randomTexts[random];
  }

  public toggleLoader(visible: boolean, text?: string, simCity: boolean = true): void {
    if (!text && simCity)
      text = this._getRandomText();

    this.loader.next({ showLoader: visible, text: text });
  }

  public getLoader(): Observable<any> {
    return this.loader.asObservable();
  }

  public register(component: LoaderComponent) {
    this._loader = component;
  }
}
