import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { SINGLETON_INJECTABLE_TYPE } from '../../common/models/constants.model';
import { environment } from '../../environments/environment';
import { Project } from '../models/project.model';
import { ProjectSearch } from '../models/project-search.model';
import { ProjectAction } from '../models/project-action.model';
import { ProjectActionStatus } from '../models/project-action-status.model';
import { ProjectActionStatusDisplayName } from '../enums/project-action-status-display-name.enum';
import { ProjectDetail } from '../models/details/project-detail.model';
import { ProjectNote } from '../models/project-note.model'
import { IDatePickerConfig, ECalendarValue } from 'ng2-date-picker';
import { ProjectHistorySearch } from '../models/history/project-history-search.model';
import { ProjectFilterResult } from '../models/project-filter-result.model';


@Injectable({
  providedIn: SINGLETON_INJECTABLE_TYPE,
})
export class ProjectService {

  constructor(
    public http: HttpClient,
    private router: Router
  ) {
  }

  public selectedProjectKey: string | null = null;
  public historyFilterInit: ProjectHistorySearch | null = null;
  public editActionKey: string | null = null;
  public isPmForSelectedProject: boolean | null = null;
  public isTaskManagerForSelectedProject: boolean = false;
  public isPmForAnySelectedProjectInContract: boolean | null = null;

  public projectActionSaved: Subject<boolean | undefined> = new Subject();
  public onProjectActionSaved: Observable<boolean | undefined> = this.projectActionSaved.asObservable();

  public projectNoteSaved: Subject<ProjectNote | null> = new Subject();
  public onProjectNoteSaved$: Observable<ProjectNote | null> = this.projectNoteSaved.asObservable();

  public selectedProjectChanged: Subject<void> = new Subject();
  public onSelectedProjectChanged: Observable<void> = this.selectedProjectChanged.asObservable();

  public editProjectAction: Subject<string> = new Subject();
  public onEditProjectAction: Observable<string> = this.editProjectAction.asObservable();

  public projectActionCountUpdate: Subject<number | undefined> = new Subject();
  public onProjectActionCountUpdate: Observable<number | undefined> = this.projectActionCountUpdate.asObservable();

  public getFilteredProjects(filter: ProjectSearch): Observable<ProjectFilterResult> {
    return this.http.post<ProjectFilterResult>(`${environment.baseServiceUrl}/api/project`, filter);
  }

  public getProjectDetails(projectKey: string): Observable<ProjectDetail> {
    return this.http.get<ProjectDetail>(`${environment.baseServiceUrl}/api/project/${projectKey}/details`);
  }

  //Note: Eventually may want this to come from server instead of hardcoded on client
  public getActionStatuses(): ProjectActionStatus[] {
    let statuses: ProjectActionStatus[] = [ 
      new ProjectActionStatus(1, ProjectActionStatusDisplayName.Open), 
      new ProjectActionStatus(2, ProjectActionStatusDisplayName.InProgress), 
      new ProjectActionStatus(3, ProjectActionStatusDisplayName.ImpedimentEncountered), 
      new ProjectActionStatus(4, ProjectActionStatusDisplayName.Completed)
    ];
    return statuses;
  }
}
