import { Injectable } from "@angular/core";
import { SINGLETON_INJECTABLE_TYPE } from "../../common/models/constants.model";
import { IStorageService } from "./iStorageService";

@Injectable({
    providedIn: SINGLETON_INJECTABLE_TYPE
  })
export class SessionStorageService implements IStorageService {
    private readonly _sessionStorage: Storage = sessionStorage;

    getStringItem(key: string): string | null {
        const result = this._sessionStorage.getItem(key);
        return result;
    }
    setStringItem(key: string, data: string): void {
        this._sessionStorage.setItem(key, data);
    }
    

    getItem<T>(key: string): T | null {
        const resultString = this._sessionStorage.getItem(key);
        if (resultString != null) {
            const result = JSON.parse(resultString) as T;
            return result;
        } else {
            return resultString;
        }
    }

    setItem<T>(key: string, data: T): void {
        const resultString = JSON.stringify(data);
        this._sessionStorage.setItem(key, resultString);
    }
    removeItem(key: string): void {
        this._sessionStorage.removeItem(key);
    }
    clearAllItems(): void {
        this._sessionStorage.clear();
    }
    
}