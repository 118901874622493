export class UserRoleTypes {
    public isAdmin: boolean = false;
    public isElevated: boolean = false;
    public isGeneralRoleType: boolean = false;
    public isPM: boolean = false;
    public PMEmployeeNumber: string | null = null;
    public isPermissionDemoMode: boolean = false;
    public isTaskManager: boolean = false;
    public taskManagerEmployeeNumber: string | null = null;
    public isSupervisor: boolean = false;
}
