import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { IInputNumberRendererParams } from './iinput-number-renderer-params.model';
import { debounceTime, from, Subject } from 'rxjs';

@Component({
  selector: "input-number-cell-renderer",
  templateUrl: "./input-number-cell-renderer.component.html",
  styleUrls: ["./input-number-cell-renderer.component.scss"]
})
export class InputNumberCellRendererComponent implements ICellRendererAngularComp {
  public value: number = 0.0;
  public canEdit: boolean = false;
  public minValue!: number;
  public maxValue!: number;
  public isPercentage: boolean = false;
  public isDisabled: boolean = false;

  private _previousValue: number = 0.0;

  public params!: IInputNumberRendererParams;

  private _fieldName!: string;
  public inputChange = new Subject();

  constructor() {
  }

  agInit(params: IInputNumberRendererParams): void {
    this.params = params;
    //Don't display number input for pinned rows
    this.canEdit = params.canEdit && params.node.rowPinned == null;
    this.isPercentage = params.isPercentage;
    
    this.minValue = params.minValue;
    this.maxValue = params.maxValue;

    if (params.data != null) {
      this.value = params.data[params.fieldName];
    }
    
    this.isDisabled = params.isDisabled;

    this._fieldName = params.fieldName;

    this._previousValue = this.value;
  }

  refresh(params: any): boolean {
    return false;
  }

  public onNumberValueChange(event: any): void {
    this.sanitizeValueOnValidation();
    if (this.value == null) {
      this.value = 0.0;
    }
    this.updateNumberValueOnChangeEvent();
  }



  public onNumberValueKeyup(event: any): void {
    this.inputChange.next(event);
    from(this.inputChange.asObservable()).pipe(debounceTime(2000)).subscribe(() => {
      this.updateNumberValueOnChangeEvent();
    });
  }

  public onNumberValueKeydown(event: any): void {
    this.sanitizeValueOnValidation();
  }

  private sanitizeValueOnValidation():  void{
    if (this.value > this.maxValue || this.value < this.minValue) {
      this.value = this._previousValue;
    }
    if (this.isPercentage) {
      if (this.value != null && !Number.isInteger(this.value)) {
        this.value = Math.round(this.value);
      }
    }
  }

  private updateNumberValueOnChangeEvent(): void {
    if (this._previousValue != this.value) {
      this._previousValue = this.value;
      this.params.data[this._fieldName] = this.value;
      this.params.node.data[this._fieldName] = this.value;
      this.params.onValueChangedHandler(this.params);
    }  
  }
}
