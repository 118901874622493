import { Observable, Subject } from "rxjs";

export class PendingQueueRequest<T> {
    public request: Observable<T>;
    public subscription: Subject<any>;

    constructor(request: Observable<T>, subscription: Subject<any>) {
        this.request = request;
        this.subscription = subscription;
    }
}