import { Inject, Injectable} from '@angular/core';
import { SINGLETON_INJECTABLE_TYPE } from '../../common/models/constants.model';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable()
export class GoogleAnalyticsService {
    private _documentService!: Document;

    constructor(@Inject(DOCUMENT) documentService: Document) {
        this._documentService = documentService;
    }

    public insertAnalyticsInHtmlDocument(): void {
        if (environment.enableGoogleAnalytics) {
            let externalScriptTag: Element = this._documentService.createElement('script');
            externalScriptTag.setAttribute('async', 'true');
            externalScriptTag.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`);

            this._documentService.head.appendChild(externalScriptTag);

            let scriptTag: Element = this._documentService.createElement('script');
            scriptTag.innerHTML = ` window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${environment.googleAnalyticsId}'); `;
            this._documentService.head.appendChild(scriptTag);
        }
    }
}
