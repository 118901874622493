import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { AdditionalFiltersComponent } from './components/filters/additional-filters.component';
import { SavedFiltersComponent } from './components/filters/saved-filters.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectHeaderMetadataInterceptor } from './interceptors/project-header-metadata-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProjectPortfolioCoreModule } from '../core/core.module';
import { ProjectFilterViewService } from './services/project-filter-view.service';
import { ProjectUserPermissionService } from './services/project-user-permission-service';

@NgModule({
  declarations: [
    SavedFiltersComponent,
    AdditionalFiltersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconSpriteModule,
    NgSelectModule,
    ProjectPortfolioCoreModule
  ],
  exports: [
    SavedFiltersComponent,
    AdditionalFiltersComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProjectHeaderMetadataInterceptor,
      multi: true
    },
    ProjectFilterViewService,
    ProjectUserPermissionService
  ]
})
export class CommonProjectModule { }
