 <div class="saved-filters-container">
   <button class="saved-filter-button" (click)="toggleMenu($event)" [ngClass]="{'showing': isSavedFiltersMenuShowing}">
     <span class="icon-primary" [ngClass]="{'icon-white': isSavedFiltersMenuShowing}">Saved Filters</span>
     <svg-icon-sprite class="chevron-down icon-primary" [ngClass]="{'icon-white': isSavedFiltersMenuShowing}" [src]="'assets/sprites/sprite.svg#icon-chevron-down'" [viewBox]="'0 0 32 32'" [width]="'16px'" [height]="'16px'"></svg-icon-sprite>
   </button>

   <div class="wide" *ngIf="isSavedFiltersMenuShowing" (click)="$event.stopPropagation()">
     <div class="saved-filter" *ngIf="!isSavingNewFilter && filterViews.length">
       <div>
         <label class="white inline-block cursor-pointer" for="saved">Select Saved Filter:</label>
         <ng-select class="block focus-light-md" id="saved" (change)="setSelectedFilterView($event)" [(ngModel)]="selectedFilterView.filterViewKey" [multiple]="false" placeholder="---No default saved filter selected---">
            <!--Workaround for ng-option.  Ng-select package doesn't handle value set to null properly -->
            <ng-option [value]="-1">---No default saved filter selected---</ng-option>
            <ng-option *ngFor="let filterView of filterViews" [value]="filterView.filterViewKey">
             {{filterView.filterViewName}}
            </ng-option>
         </ng-select>

       </div>
       <ng-container *ngIf="selectedFilterView?.filterViewKey != null">
        <div>
          <label class="white inline-block cursor-pointer">Flag Type:</label> <span class="white inline-block">{{formattedFlagTypeNames}}</span>
        </div>
        <div>
          <label class="white inline-block cursor-pointer" for="office">Project Office:</label> <span class="white inline-block">{{formattedOfficeNames}}</span>
        </div> 
        <div>
          <label class="white inline-block cursor-pointer" for="department">Project Department:</label> <span class="white inline-block">{{formattedDepartmentNames}}</span>
        </div>
        <div>
          <label class="white inline-block cursor-pointer" for="pm1Office">PM 1 Office:</label> <span class="white inline-block">{{formattedPM1OfficeNames}}</span>
        </div>
        <div>
          <label class="white inline-block cursor-pointer" for="pm1Department">PM 1 Department:</label> <span class="white inline-block">{{formattedPM1DepartmentNames}}</span>
        </div>
        <div>
          <label class="white inline-block cursor-pointer" for="client">Owner Client Type:</label> <span class="white inline-block">{{formattedOwnerClientTypeNames}}</span>
        </div>
        <div>
          <label class="white inline-block cursor-pointer" for="pm1">PM 1:</label> <span class="white inline-block">{{formattedPM1EmployeeNames}}</span>
        </div>
        <div>
          <label class="white inline-block cursor-pointer" for="pm2">PM 2:</label> <span class="white inline-block">{{formattedPM2EmployeeNames}}</span>
        </div> 
        <div>
          <label class="white cursor-pointer" for="designBuild">Design Build Projects</label><input name="designBuild" type="checkbox" disabled="false" [(ngModel)]="selectedFilterView.isDesignBuild" />
        </div>  
        <div>
          <label class="white cursor-pointer" for="activeProjects">Active Projects</label>
          <input name="activeProjects" type="checkbox" disabled="true" class="disabledCheckbox" [(ngModel)]="selectedFilterView.isActiveProjectStatus" />
          <label class="white cursor-pointer" for="completedProjects" style="padding-left: 10px">Completed Projects</label>
          <input name="completedProjects" type="checkbox" disabled="true" class="disabledCheckbox" [(ngModel)]="selectedFilterView.isCompletedProjectStatus" />
        </div>  

        <label class="white cursor-pointer" for="defaultFilter">
          <input class="slide-toggle focus-light-md" id="defaultFilter" type="checkbox" [(ngModel)]="selectedFilterView.isDefault" />
          Default Filter
        </label>

      
       <div class="saved-filter-button-container">
         <button class="secondary focus-light-md" (click)="applyFilterView()" [disabled]="isFilterViewSaveInProgress">Apply</button>
         <button class="secondary focus-light-md" [disabled]="isFilterViewSaveInProgress" (click)="deleteConfirm.showModal()">Delete Filter</button>
       </div>

        <dialog #deleteConfirm>
          <p>Are you sure you would like to delete the saved view?</p>
          <div class="btn-container txt-center">
            <button class="secondary" (click)="deleteFilter()">Ok</button>
            <button class="gray" (click)="deleteConfirm.close()">Cancel</button>
          </div>
        </dialog>
      </ng-container>
     </div>
     <div *ngIf="!filterViews.length">
       <p class="white">You have no saved filters</p>
     </div>
   </div>
</div>
