import { BrowserCacheLocation, Configuration } from "@azure/msal-browser";
import { environment } from '../environments/environment';

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.authClientId,
        authority: 'https://login.microsoftonline.com/eb5bb4f9-ca67-467a-85b5-42d23ee13edb', //Includes tenantId in URI
        redirectUri: '/auth', // This URI is registered on Azure's App Registration.  window.location.origin
        postLogoutRedirectUri: '/',
        clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenges.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
    }
}

export const protectedResources = {
    api: {
        scopes: {
            read: [`api://${environment.authClientId}/AuthUsers.Read.All`]
        }
    }
}