
<form [formGroup]="additionalFiltersForm">

  <button class="apply-filter-button focus-dark-md" (click)="toggleMenu($event)" [ngClass]="{'showing': isAppliedFiltersMenuShowing, 'applied':isFilterApplied }">
    <svg-icon-sprite class="filter" [ngClass]="{'icon-primary': !isFilterApplied, 'icon-green': isFilterApplied, 'icon-white': isAppliedFiltersMenuShowing}" [src]="'assets/sprites/sprite.svg#icon-filter'" [viewBox]="'0 0 32 32'" [width]="'16px'" [height]="'16px'"></svg-icon-sprite>
    <span class="icon-green" [ngClass]="{'icon-white': isAppliedFiltersMenuShowing}" *ngIf="isFilterApplied">
      Filters Applied
    </span>
    <span class="icon-primary" [ngClass]="{'icon-white': isAppliedFiltersMenuShowing}" *ngIf="!isFilterApplied">
      Apply Filters
    </span>
    <svg-icon-sprite class="chevron-down" [ngClass]="{'icon-primary': !isFilterApplied, 'icon-green': isFilterApplied, 'icon-white': isAppliedFiltersMenuShowing}" [src]="'assets/sprites/sprite.svg#icon-chevron-down'" [viewBox]="'0 0 32 32'" [width]="'16px'" [height]="'16px'"></svg-icon-sprite>
  </button>

  <div class="wide" *ngIf="isAppliedFiltersMenuShowing" (click)="$event.stopPropagation()">
    <div class="filter-container">
      <div>
        <label class="white inline-block cursor-pointer" for="flagType" (click)="openFlagType()">Flag Type</label>

        <ng-select #flagType id="flagType" [ngClass]="'focus-light-md m-t--25'" [formControlName]="FLAG_TYPE_FORM_CONTROL_NAME" [multiple]="true" typeToSearchText="Please enter a flag type" (change)="setFilterSelection($event, FLAG_TYPE_FORM_CONTROL_NAME)" class="block">
          <ng-option *ngFor="let flag of filteredFlags | async" [value]="flag.id">
            <span class="display-name">{{flag.name}}</span>
          </ng-option>
        </ng-select>

      </div>
      <div>
        <label class="white inline-block cursor-pointer" for="office" (click)="openOffice()">Project Office</label>
        <ng-select #office [ngClass]="'focus-light-md'" id="office" [formControlName]="OFFICE_FORM_CONTROL_NAME" [multiple]="true" typeToSearchText="Please enter a project office name" (change)="setFilterSelection($event, OFFICE_FORM_CONTROL_NAME)" class="block">
          <ng-option *ngFor="let office of filteredOffices | async" [value]="office.id">
            <span class="display-name">{{office.name}}</span>
          </ng-option>
        </ng-select>

      </div>
      <div>
        <label class="white inline-block cursor-pointer" for="department" (click)="openDepartment()">Project Department</label>
        <ng-select #department [ngClass]="'focus-light-md'" id="department" [formControlName]="DEPARTMENT_FORM_CONTROL_NAME" [multiple]="true" typeToSearchText="Please enter a project department name" (change)="setFilterSelection($event, DEPARTMENT_FORM_CONTROL_NAME)" class="block">
          <ng-option *ngFor="let department of filteredDepartments | async" [value]="department.id">
            <span class="display-name">{{department.name}}</span>
          </ng-option>
        </ng-select>

      </div>
      <div>
        <label class="white inline-block cursor-pointer" for="pm1Office" (click)="openPM1Office()">PM 1 Office</label>
        <ng-select #pm1Office [ngClass]="'focus-light-md'" id="pm1Office" [formControlName]="PM1_OFFICE_FORM_CONTROL_NAME" [multiple]="true" typeToSearchText="Please enter a project manager 1's office" (change)="setFilterSelection($event, PM1_OFFICE_FORM_CONTROL_NAME)" class="block">
          <ng-option *ngFor="let office of filteredProjectManager1Offices | async" [value]="office.officeCode">
            <span class="display-name">{{office.name}}</span>
          </ng-option>
        </ng-select>

      </div>
      <div>
        <label class="white inline-block cursor-pointer" for="pm1Department" (click)="openPM1Department()">PM 1 Department</label>
        <ng-select #pm1Department [ngClass]="'focus-light-md'" id="pm1Department" [formControlName]="PM1_DEPARTMENT_FORM_CONTROL_NAME" [multiple]="true" typeToSearchText="Please enter a project manager 1's department" (change)="setFilterSelection($event, PM1_DEPARTMENT_FORM_CONTROL_NAME)" class="block">
          <ng-option *ngFor="let department of filteredProjectManager1Departments | async" [value]="department.departmentCode">
            <span class="display-name">{{department.name}}</span>
          </ng-option>
        </ng-select>

      </div>
      <div>
        <label class="white inline-block cursor-pointer" for="owner" (click)="openOwner()">Owner Client Type</label>
        <ng-select #owner [ngClass]="'focus-light-md'" id="owner" [formControlName]="OWNER_CLIENT_TYPE_FORM_CONTROL_NAME" [multiple]="true" typeToSearchText="Please enter an owner client type" (change)="setFilterSelection($event, OWNER_CLIENT_TYPE_FORM_CONTROL_NAME)" class="block">
          <ng-option *ngFor="let ownerClientType of filteredOwnerClientTypes | async" [value]="ownerClientType.id">
            <span class="display-name">{{ownerClientType.name}}</span>
          </ng-option>
        </ng-select>

      </div>
      <div>
        <label class="white inline-block cursor-pointer" for="pm1Employee" (click)="openPM1Employee()">PM 1</label>
        <ng-select #pm1Employee [ngClass]="'focus-light-md'" id="pm1Employee" [formControlName]="PM1_EMPLOYEE_FORM_CONTROL_NAME" [multiple]="true" typeToSearchText="Please enter a project manager 1" (change)="setFilterSelection($event, PM1_EMPLOYEE_FORM_CONTROL_NAME)" class="block">
          <ng-option *ngFor="let employee of filteredProjectManager1Employees | async" [value]="employee.employeeNumber">
            <span class="display-name">{{employee.displayName}}</span>
          </ng-option>
        </ng-select>

      </div>
      <div>
        <label class="white inline-block cursor-pointer" for="pm2Employee" (click)="openPM2Employee()">PM 2</label>
        <ng-select #pm2Employee [ngClass]="'focus-light-md'" id="pm2Employee" [formControlName]="PM2_EMPLOYEE_FORM_CONTROL_NAME" [multiple]="true" typeToSearchText="Please enter a project manager 2" (change)="setFilterSelection($event, PM2_EMPLOYEE_FORM_CONTROL_NAME)" class="block">
          <ng-option *ngFor="let employee of filteredProjectManager2Employees | async" [value]="employee.employeeNumber">
            <span class="display-name">{{employee.displayName}}</span>
          </ng-option>
        </ng-select>

      </div>
      <div>
        <label class="white m-r--50">Status:</label>
        <label class="white cursor-pointer m-r--50" for="activeStatus">
          <input class="focus-light-md" id="activeStatus" type="checkbox" [(ngModel)]="selectedFilters.includeActive" [ngModelOptions]="{standalone: true}" />
          Active Projects
        </label>
        <label class="white cursor-pointer" for="completedStatus">
          <input class="focus-light-md" id="completedStatus" type="checkbox" [(ngModel)]="selectedFilters.includeCompleted" [ngModelOptions]="{standalone: true}" />
          Completed Projects
        </label>
      </div>
      <div>
        <label class="white cursor-pointer" for="designBuild">
          <input class="slide-toggle focus-light-md" id="designBuild" type="checkbox" [(ngModel)]="selectedFilters.isDesignBuild" [ngModelOptions]="{standalone: true}" />
          Design Build Projects
        </label>
      </div>
      <div *ngIf="isSavingNewFilter">
        <label class="white cursor-pointer" for="savedFilterName">Saved Filter Name</label>
        <input type="text" #savedFilterName id="savedFilterName" [formControlName]="NEW_FILTER_VIEW_NAME_FORM_CONTROL_NAME" />
        <p class="white font-italic m-t--50">Enter a name and hit the Save Filter button</p>
      </div>

      <div class="filter-button-container">
        <button class="secondary focus-light-md" (click)="setIsSavingNewFilter($event)" [disabled]="isNewFilterViewSaveInProgress">Save Filter</button>
        <button class="secondary focus-light-md" (click)="setFiltersApplied(true);">Apply</button>
        <button class="secondary focus-light-md" (click)="setFiltersApplied(false);" (keydown.Tab)="closeMenuUpdateServiceVariable()">Clear Filters</button>
      </div>
    </div>
  </div>
</form>
