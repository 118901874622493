import { UserRoleTypes } from "./user-role-types.model";

export class ApplicationUserDto {
    public id?: number;
    public username!: string;
    public name?: string | null;
    public employeeNumber?: string | null;
    public displayName?: string | null;
    public roleTypes!: UserRoleTypes;
    public releaseNotesVersionViewed!: number;

    constructor(username: string, name?: string | null, employeeNumber?: string | null) {
        this.username = username;
        this.name = name;
        this.employeeNumber = employeeNumber;
    }
}
