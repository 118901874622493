<button (click)="skipToMain()" class="secondary skip">Skip To Data Grid Header <span class="sr">Use ctrl + shift + ? for grid keyboard navigation help</span></button>
<banner></banner>
<loader></loader>


<main class="content" role="main" id="main">
  <router-outlet></router-outlet>
</main>

<app-footer></app-footer>
