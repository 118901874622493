<footer>
  <ul class="footer-list">
    <li *ngIf="authenticatedUser == null">
      <button class="footer-button" rel="noopener" (click)="login()" title="Login">Login</button>
    </li>
    <li class="userLabel" *ngIf="authenticatedUser != null">
      Logged in as:&nbsp;<span class="name">{{authenticatedUser.name}}</span>&nbsp;<span class="userName">({{authenticatedUser.username}})</span>
    </li>
    <li class="userLabel" *ngIf="authenticatedUser != null">
      <button class="footer-button" title="Log Out" color="primary" (click)="logOff()">Log Out</button>
    </li>
    <li *ngIf="environment?.toLowerCase() != 'production'">
      Environment: {{environment}}
    </li>
  </ul>
</footer>
