import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SINGLETON_INJECTABLE_TYPE } from '../../common/models/constants.model';
import { environment } from '../../environments/environment';
import { ProjectFilterView } from '../models/filters/project-filter-view.model';
import { ProjectFilterViewData } from '../models/filters/project-filter-view-data.model';
import { LightweightProjectFilterView } from '../models/filters/lightweight-project-filter-view.model';

@Injectable({
  providedIn: SINGLETON_INJECTABLE_TYPE,
})
export class ProjectFilterViewService {

  public filterViewSaved$: Subject<LightweightProjectFilterView> = new Subject();
  public onFilterViewSaved: Observable<LightweightProjectFilterView> = this.filterViewSaved$.asObservable();

  public filterViewApplied$: Subject<LightweightProjectFilterView> = new Subject();
  public onFilterViewApplied: Observable<LightweightProjectFilterView> = this.filterViewApplied$.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  public getAllFilterViews(): Observable<ProjectFilterViewData> {
    return this.http.get<ProjectFilterViewData>(`${environment.baseServiceUrl}/api/projectFilterView`);
  }

  public getFilterView(filterViewKey: string): Observable<ProjectFilterView> {
    return this.http.get<ProjectFilterView>(`${environment.baseServiceUrl}/api/projectFilterView/${filterViewKey}`);
  }

  public updateDefaultFilterView(filterViewKey: string, isDefault: boolean): Observable<void> {
    return this.http.post<void>(`${environment.baseServiceUrl}/api/projectFilterView/${filterViewKey}/default/${isDefault}`, filterViewKey);
  }

  public createFilterView(filterView: LightweightProjectFilterView): Observable<LightweightProjectFilterView> {
    return this.http.post<LightweightProjectFilterView>(`${environment.baseServiceUrl}/api/projectFilterView`, filterView);
  }

  public deleteFilterView(filterViewKey: string): Observable<void> {
    return this.http.delete<void>(`${environment.baseServiceUrl}/api/projectFilterView/${filterViewKey}`);
  }
}
