import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SINGLETON_INJECTABLE_TYPE } from '../../common/models/constants.model';
/**
 * Toastr wrapper service.
 * See https://www.npmjs.com/package/ngx-toastr
 * Global defaults set in core.module.ts
 *  
 * */
@Injectable({
  providedIn: SINGLETON_INJECTABLE_TYPE
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }
  /**
   * Displays a success toast notification.
   *
   * @remarks
   * This method displays a success toast notification
   *
   * @param message - Message to display in the notification
   * @param title - Optional title to display in the notification
   * @param config - Optional changes to default config for this notification
   *
   */
  public showSuccess(message: string, title?: string, config: Partial<any> = {}): void {
    this.toastr.success(message, title, config);
  }
  /**
  * Displays an error toast notification
  *
  * @remarks
  * This method displays an error toast notification
  *
  * @param message - Message to display in the notification
  * @param title - Optional title to display in the notification
  * @param config - Optional changes to default config for this notification
  *
  */
  public showError(message: string, title?: string, config: Partial<any> = {}): void {
    this.toastr.error(message, title, config);
  }
  /**
  * Displays an info toast notification.
  *
  * @remarks
  * This method displays a info toast notification
  *
  * @param message - Message to display in the notification
  * @param title - Optional title to display in the notification
  * @param config - Optional changes to default config for this notification
  *
  */
  public showInfo(message: string, title?: string, config: Partial<any> = {}): void {
    this.toastr.info(message, title, config);
  }
  /**
  * Displays a warning toast notification.
  *
  * @remarks
  * This method displays a warning toast notification
  *
  * @param message - Message to display in the notification
  * @param title - Optional title to display in the notification
  * @param config - Optional changes to default config for this notification
  *
  */
  public showWarning(message: string, title?: string, config: Partial<any> = {}): void {
    this.toastr.warning(message, title, config);
  }
  
}
