import { ENVIRONMENT_NAME_DEV } from "../common/models/constants.model";

export const environment = {
  production: false,
  baseServiceUrl: "https://ppr-dev.jmt.com",
  displayName: ENVIRONMENT_NAME_DEV,
  authClientId: "242a6fe8-877f-49ea-b300-7f20d626d0ba",
  costReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSectiond95ae812292da80d19be?filter=Projects/Project_x0020_Id eq ",
  revenueReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSectiond95ae812292da80d19be?filter=Projects/Project_x0020_Id eq ",
  estimatedWPCReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSectiond95ae812292da80d19be?filter=Projects/Project_x0020_Id eq ",
  contractMBEGoalReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/d2b82c16-96ba-44f0-b473-8efc4d575523/ReportSectionbccf3677372d16cbc80e?filter=Contracts/Contract_x0020_Id eq ",
  unbilledReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSection8a52c35122d8156a98d0?filter=Projects/Project_x0020_Id eq ",
  unpaidReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSection8a52c35122d8156a98d0?filter=Projects/Project_x0020_Id eq ",
  enableGoogleAnalytics: false,
  googleAnalyticsId: ""
};
