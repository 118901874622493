import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { SessionStorageService } from '../../../core/services/sessionStorage.service';
import { MainService } from '../../../common/services/main.service';
import { AuthService } from '../../../core/services/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, Subscription, tap, finalize } from 'rxjs';
import { ProjectFilterService } from '../../../common-project/services/project-filter.service';
import { environment } from '../../../environments/environment';
import { ENVIRONMENT_NAME_DEV, ENVIRONMENT_NAME_LOCALDEV } from 'src/common/models/constants.model';


/* temp can be deleted when populated */
interface SelectItem {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy {

  public buttonText = "SELECT";
  public selectItems: SelectItem[] = [
    { value: '0', viewValue: 'Select 1' },
    { value: '1', viewValue: 'Select 2' },
    { value: '2', viewValue: 'Select 3' },
  ];
  //main "saved" filter
  public isFilterApplied: boolean = false;
  public isMobileFilterDisplay: boolean = false;
  public showMyProjectsOnly: boolean = false;
  public showNeedsReviewedProjects: boolean = false;
  public showNeedsUpdatedProjects: boolean = false;

  public isTempAdmin: boolean = false;
  public isTempElevated: boolean = false;
  public tempPMEmployeeNumber: string | null = null;
  public tempTaskManagerEmployeeNumber: string | null = null;
  public isTempGeneral: boolean = false;
  public isTempSupervisor: boolean = false;

  public isPermissionDemoMode: boolean = false;
  public isDetailsOpen: boolean = true;
  private subscriptions = new Array<Subscription>();

  public saveMessage!: string;

  public readonly SESSION_STORAGE_IS_ADMIN_ROLE = 'isAdminRole';
  public readonly SESSION_STORAGE_IS_ELEVATED_ROLE = 'isElevatedRole';
  public readonly SESSION_STORAGE_IS_GENERAL_ROLE = 'isGeneralRole';
  public readonly SESSION_STORAGE_IS_SUPERVISOR_ROLE = 'isSupervisorRole';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setWindowWidth(event.target.innerWidth);
  }
  constructor(private _mainService: MainService, protected sessionStorageService: SessionStorageService, protected authService: AuthService, protected route: ActivatedRoute,
    protected router: Router, protected filterService: ProjectFilterService) { }

  ngOnInit(): void {
    this.setWindowWidth(window.innerWidth);

    const uiFilterAppliedSubscription$ = this._mainService.isFilterApplied.pipe(finalize(() => {
      this.subscriptions.push(uiFilterAppliedSubscription$);
    })).subscribe((applied: boolean) => {
      //set is filtered prop
      this.isFilterApplied = applied;
    }, error => {
      console.log(error);
    });


    const uiDetailsSubscription$ = this._mainService.onProjectDetailsDisplayChanged.pipe(finalize(() => {
        this.subscriptions.push(uiDetailsSubscription$);
      })).subscribe((isOpen: boolean) => {
        this.isDetailsOpen = isOpen;
      }, error => {
        console.log(error);
      });

    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
                      .subscribe({next: () => {
                        this.isPermissionDemoMode = this.route.snapshot.queryParamMap.get('isPermissionDemoMode') === 'true'
                          && (environment.displayName === ENVIRONMENT_NAME_DEV || environment.displayName === ENVIRONMENT_NAME_LOCALDEV);

                        this.isTempAdmin = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_IS_ADMIN_ROLE) === 'true';
                        this.isTempElevated = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_IS_ELEVATED_ROLE) === 'true';
                        this.tempPMEmployeeNumber = this.sessionStorageService.getStringItem(this.authService.SESSION_STORAGE_IMPERSONATED_PM_EMPLOYEE_NUMBER);
                        this.tempTaskManagerEmployeeNumber = this.sessionStorageService.getStringItem(this.authService.SESSION_STORAGE_IMPERSONATED_TASKMANAGER_EMPLOYEE_NUMBER);
                        this.isTempGeneral = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_IS_GENERAL_ROLE) === 'true';
                        this.isTempSupervisor = this.sessionStorageService.getStringItem(this.SESSION_STORAGE_IS_SUPERVISOR_ROLE) === 'true';

                        this.authService.userRoleTypes.isPermissionDemoMode = this.isPermissionDemoMode;
                        if (this.isPermissionDemoMode) {
                          this.authService.userRoleTypes.isAdmin = this.isPermissionDemoMode ? this.isTempAdmin : true;
                          this.authService.userRoleTypes.isElevated = this.isTempElevated;
                          this.authService.userRoleTypes.PMEmployeeNumber = this.tempPMEmployeeNumber;
                          this.authService.userRoleTypes.taskManagerEmployeeNumber = this.tempTaskManagerEmployeeNumber;
                          this.authService.userRoleTypes.isSupervisor = this.isTempSupervisor;
                          this.authService.userRoleTypes.isGeneralRoleType = this.isTempGeneral;
                          //if nothing is selected set as General user
                          if (!this.isTempAdmin && !this.isTempElevated && !this.tempPMEmployeeNumber && !this.tempTaskManagerEmployeeNumber && !this.isTempSupervisor) {
                            this.authService.userRoleTypes.isGeneralRoleType = true;
                            this.sessionStorageService.setStringItem(this.SESSION_STORAGE_IS_GENERAL_ROLE, String(true));
                          } else {
                            this.authService.userRoleTypes.isGeneralRoleType = false;
                            this.sessionStorageService.removeItem(this.SESSION_STORAGE_IS_GENERAL_ROLE);
                          }
                        } else {
                          this.sessionStorageService.removeItem(this.authService.SESSION_STORAGE_IMPERSONATED_PM_EMPLOYEE_NUMBER);
                          this.sessionStorageService.removeItem(this.authService.SESSION_STORAGE_IMPERSONATED_TASKMANAGER_EMPLOYEE_NUMBER);
                        }
                      }});

      const saveStartedSub = this._mainService.onSaveToDisplayInBannerStarted.pipe(finalize(() => {
        this.subscriptions.push(saveStartedSub);
      })).subscribe({next: () => {
        this.saveMessage = "Saving...";
      }});

      const saveCompletedSub = this._mainService.onSaveToDisplayInBannerCompleted.pipe(finalize(() => {
        this.subscriptions.push(saveCompletedSub);
      })).subscribe({next: (saveMessage: string) => {
        this.saveMessage = saveMessage;
      }});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private setWindowWidth(width: number): void {
    this._mainService.windowWidthChanged.next(width);
    //should we display the mobile or desktop "saved filter"?
    this._mainService.setMobileProjectFilterDisplay();
  }

  public setIsAdmin(): void {
    this.sessionStorageService.setStringItem(this.SESSION_STORAGE_IS_ADMIN_ROLE, String(this.isTempAdmin));
    this.authService.userRoleTypes.isAdmin = this.isTempAdmin;
  }

  public setIsElevated(): void {
    this.sessionStorageService.setStringItem(this.SESSION_STORAGE_IS_ELEVATED_ROLE, String(this.isTempElevated));
    this.authService.userRoleTypes.isElevated = this.isTempElevated;
  }

  public setIsGeneral(): void {
    this.sessionStorageService.setStringItem(this.SESSION_STORAGE_IS_GENERAL_ROLE, String(this.isTempGeneral));
    this.authService.userRoleTypes.isGeneralRoleType = this.isTempGeneral;
  }

  public setPMEmployeeNumber(): void {
    if (this.tempPMEmployeeNumber != null) {
      this.sessionStorageService.setStringItem(this.authService.SESSION_STORAGE_IMPERSONATED_PM_EMPLOYEE_NUMBER, this.tempPMEmployeeNumber);
    }

    this.authService.forceUserInfoRetrievalFromServer();

    window.location.reload();
  }

  public setTaskManagerEmployeeNumber(): void {
    if (this.tempTaskManagerEmployeeNumber != null) {
      this.sessionStorageService.setStringItem(this.authService.SESSION_STORAGE_IMPERSONATED_TASKMANAGER_EMPLOYEE_NUMBER, this.tempTaskManagerEmployeeNumber);
    }

    this.authService.forceUserInfoRetrievalFromServer();

    window.location.reload();
  }

  public setIsSupervisor(): void {
    this.sessionStorageService.setStringItem(this.SESSION_STORAGE_IS_SUPERVISOR_ROLE, String(this.isTempSupervisor));
    this.authService.userRoleTypes.isSupervisor = this.isTempSupervisor;
  }

  public toggleMyProjects(): void {
    this.filterService.setMyProjectsOnly = this.showMyProjectsOnly;

  }

  public toggleNeedsReviewed(): void {
    this.filterService.setNeedsReviewedProjects = this.showNeedsReviewedProjects;
  }

  public toggleNeedsUpdated(): void {
    this.filterService.setNeedsUpdatedProjects = this.showNeedsUpdatedProjects;
  }

  public homeClicked(): void {
    this.router.navigate(["/"]);
  }
}
