<header role="banner">
  <div class="header" [ngClass]="{'detailsOpen': isDetailsOpen}">
    <div class="logo-container" (click)="homeClicked()">
      <svg-icon-sprite [ngClass]="'icon-white'" title="Project Portfolio Review" [src]="'assets/sprites/sprite.svg#icon-logo'" [viewBox]="'0 0 150 126'" [width]="'50'" [height]="'41px'"></svg-icon-sprite>
    </div>
    <div class="filter-section-bg apply" additional-filters [isFilterApplied]="isFilterApplied" [isMobileFilterDisplay]="isMobileFilterDisplay">
    </div>
    <div class="filter-section-bg saved">
      <saved-filters *ngIf="!isMobileFilterDisplay" [isMobileDisplay]="isMobileFilterDisplay"></saved-filters>
    </div>
    <div class="my-projects-needs-reviewed-and-needs-updated">
      <label for="myProjects" class="cursor-pointer">
        <input id="myProjects" title="View projects where you are a PM or Task Manager" name="myProjects" class="slide-toggle focus-dark-md" type="checkbox" [(ngModel)]="showMyProjectsOnly" [ngModelOptions]="{standalone: true}" (change)="toggleMyProjects()" />
        View My Projects
      </label>
    </div>
    <div class="my-projects-needs-reviewed-and-needs-updated">
      <label for="needsReviewed" class="cursor-pointer">
        <input id="needsReviewed" title="View projects that need to be reviewed" name="needsReviewed" class="slide-toggle focus-dark-md" type="checkbox" [(ngModel)]="showNeedsReviewedProjects" [ngModelOptions]="{standalone: true}" (change)="toggleNeedsReviewed()" />
        Needs Reviewed
      </label>
    </div>
    <div class="my-projects-needs-reviewed-and-needs-updated">
      <label for="needsUpdated" class="cursor-pointer">
        <input id="needsUpdated" title="View projects that need to be updated" name="needsUpdated" class="slide-toggle focus-dark-md" type="checkbox" [(ngModel)]="showNeedsUpdatedProjects" [ngModelOptions]="{standalone: true}" (change)="toggleNeedsUpdated()" />
        Needs Updated
      </label>
    </div>
    <div class="saveMessage-container">
      <span class="saveMessage">{{saveMessage}}</span>
    </div>
    <div class="help-link-container">
      <a href="https://jmt365.sharepoint.com/sites/myJMT/SharedDocuments/Forms/Information%20Systems.aspx?id=%2Fsites%2FmyJMT%2FSharedDocuments%2FInformation%20Systems%2FProject%20Portfolio%20Manual%2Epdf&parent=%2Fsites%2FmyJMT%2FSharedDocuments%2FInformation%20Systems" target="_blank" class="help-link">Help</a>
    </div>

  <ng-container *ngIf="isPermissionDemoMode">
    <ul class="impersonation">
      <li><label for="isTempAdmin">Impersonate an Admin</label>
        <input name="isTempAdmin" type="checkbox" class="slide-toggle" [(ngModel)]="isTempAdmin" (change)="setIsAdmin()" />
      </li>
      <li><label for="isTempElevated">Impersonate an Elevated role</label>
        <input name="isTempElevated" type="checkbox" class="slide-toggle" [(ngModel)]="isTempElevated" (change)="setIsElevated()" />
      </li>
      <li><label for="isTempGeneral">Impersonate a General role</label>
        <input name="isTempGeneral" type="checkbox" class="slide-toggle" [(ngModel)]="isTempGeneral" (change)="setIsGeneral()" />
      </li>
      <li><label for="isTempPM">Impersonate a PM.  Enter employee number</label>
        <input name="isTempPM" style="width:90px" type="textbox" [(ngModel)]="tempPMEmployeeNumber" placeholder="Enter employee number" (change)="setPMEmployeeNumber()" />
      <li><label for="isTempTaskManager">Impersonate Task Manager. Enter employee number</label>
        <input name="isTempTaskManager" style="width:90px" type="textbox" [(ngModel)]="tempTaskManagerEmployeeNumber" placeholder="Enter employee number" (change)="setTaskManagerEmployeeNumber()" />
      </li>
      <li><label for="isTempSupervisor">Impersonate a Supervisor role</label>
        <input name="isTempSupervisor" type="checkbox" class="slide-toggle" [(ngModel)]="isTempSupervisor" (change)="setIsSupervisor()" />
      </li>
    </ul>
  </ng-container>
  </div>
</header>
