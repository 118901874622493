export class AdditionalFiltersModel {
    public flagIds: number[] = [];
    public departmentIds: string[] = [];
    public officeIds: string[] = [];
    public ownerClientTypeIds: string[] = [];
    public isDesignBuild: boolean = false;
    public isCleared: boolean = false;
    public myProjectsOnly: boolean = false;
    public includeActive: boolean = true;
    public includeCompleted: boolean = false;
    public projectManager1DepartmentCodes: string[] = [];
    public projectManager1OfficeCodes: string[] = [];
    public projectManager1EmployeeNumbers: string[] = [];
    public projectManager2EmployeeNumbers: string[] = [];
    public isNeedsReviewed: boolean = false;
    public isNeedsUpdated: boolean = false;
}
