import { Component, HostBinding, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { finalize, Subscription } from 'rxjs';
import { MainService } from '../common/services/main.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'project-portfolio-review';
  public isProjectLayout: boolean = false;
  public isProjectDetailsOpen: boolean = false;
  private mainSubscription = new Subscription();

  @HostListener('click', ['$event.target'])
  onClick() {
    //close any open menus
    this._mainService.broadcastClickOutsideMenu();
  }
  constructor(private _mainService: MainService, private renderer: Renderer2) {
  }

  @HostBinding('class.open') get isOpen() { return this.isProjectDetailsOpen; }

  ngOnInit(): void {
    const isProjectPageSubscription$ = this._mainService.isProjectPage.pipe(finalize(() => {
      this.mainSubscription.add(isProjectPageSubscription$);
    })).subscribe((value: boolean) => {
      this.isProjectLayout = value;
    }, error => {
      console.log("error getting is project layout");
    });

    const isProjectDetailsOpen$ = this._mainService.onProjectDetailsDisplayChanged.subscribe((value: boolean) => {
      this.isProjectDetailsOpen = value;
    }, error => {
      console.log("error getting is project details open/close");
    });
  }

  ngOnDestroy(): void {
    if (this.mainSubscription) {
      this.mainSubscription.unsubscribe();
    }
  }

  public skipToMain(): void {
    let firstHeaderCell = document.querySelector('.ag-header-cell[col-id="FlagCount"]');
    if (firstHeaderCell) {
      //@ts-ignore
      firstHeaderCell.focus();
    }
    
  }

}
