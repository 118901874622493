import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { StaticLoaderComponent } from './components/loader/static-loader.component';
import { ImageCellRendererComponent } from './ag-grid/cell-renderers/image-cell-renderer.component';
import { AccountingCurrencyPipe } from './pipes/accounting-currency.pipe';

import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { CurrencyCellRendererComponent } from './ag-grid/cell-renderers/currency-cell-renderer.component';
import { InputNumberCellRendererComponent } from './ag-grid/cell-renderers/input-number-cell-renderer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderByPropertyPipe } from './pipes/order-by-property.pipe';
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';
import { ActivityNameCellRendererComponent } from './ag-grid/cell-renderers/activity-name-cell-renderer.component';
import { ImageCellViewboxPipe } from './ag-grid/pipes/image-cell-viewbox.pipe';

@NgModule({
  declarations: [
    StaticLoaderComponent,
    LoaderComponent,
    ImageCellRendererComponent,
    AccountingCurrencyPipe,
    CurrencyCellRendererComponent,
    InputNumberCellRendererComponent,
    OrderByPropertyPipe,
    ReleaseNotesComponent,
    ActivityNameCellRendererComponent,
    ImageCellViewboxPipe
  ],
  imports: [
    CommonModule,
    IconSpriteModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    StaticLoaderComponent,
    LoaderComponent,
    ImageCellRendererComponent,
    AccountingCurrencyPipe,
    CurrencyCellRendererComponent,
    InputNumberCellRendererComponent,
    OrderByPropertyPipe,
    ReleaseNotesComponent,
    ActivityNameCellRendererComponent
  ],
  providers: [
    DecimalPipe
  ]
})
export class ProjectPortfolioCommonModule { }
