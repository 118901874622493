import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { UserInfoResolver } from './user-info.resolver';

const routes: Routes = [{ path: '', redirectTo: 'projects', pathMatch: 'full' },
  { path: 'projects', canActivate: [MsalGuard], resolve: { 
                                                            'userInfo': (route: ActivatedRouteSnapshot) => inject(UserInfoResolver).resolve(route)  
                                                         },
                                                loadChildren: () => import('../projects/projects.module').then(m => m.ProjectsModule) },
  { path: 'auth', component: MsalRedirectComponent },
  //{ path: "**", redirectTo: "not-found" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
