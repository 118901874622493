export class Queue<T> {
    private _queue: T[] = [];
    private _poisonMessageQueue: T[] = [];

    public enqueue(item: T): void {
        this._queue.push(item);
    }

    public dequeue(): T | undefined {
        const firstItem = this._queue.shift();
        return firstItem;
    }

    public enqueueToPoisonMessageQueue(item: T): void {
        this._poisonMessageQueue.push(item);
    }

    public dequeuePoisonMessageQueue(): T | undefined {
        const firstItem = this._poisonMessageQueue.shift();
        return firstItem;
    }

    public get queueLength(): number {
        return this._queue.length;
    }

    public get poisonMessageQueueLength(): number {
        return this._poisonMessageQueue.length;
    }
}