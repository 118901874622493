import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { SINGLETON_INJECTABLE_TYPE } from '../../common/models/constants.model';
import { environment } from '../../environments/environment';
import { Employee } from '../models/employee.model';
import { environmentAll } from '../../environments/environment-all';

@Injectable({
  providedIn: SINGLETON_INJECTABLE_TYPE,
})
export class EmployeeService {

    constructor(public http: HttpClient) {
    }

    public assignedToEmployees: Employee[] = [];

    public getAllActiveEmployees(): Observable<Array<Employee>> {
        return this.http.get<Array<Employee>>(`${environment.baseServiceUrl}/api/employee`);
    }

    public updateEmployeeHasViewedReleaseNotes(): Observable<void> {
      return this.http.post<void>(`${environment.baseServiceUrl}/api/employee/releaseNotes/${environmentAll.releaseVersion}`, {});
    }
}
