import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../core/services/auth.service';
import { SINGLETON_INJECTABLE_TYPE } from '../common/models/constants.model';

@Injectable({
  providedIn: SINGLETON_INJECTABLE_TYPE
})
export class UserInfoResolver {

  constructor(protected authService: AuthService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const observableValue = new Observable<boolean>((observer: any) => {
      this.authService.getUserInfo().subscribe({next: (result: any) => {
          if (result.username != null) {
            observer.next(true);
          } else {
            observer.next(false);
          }
      }, error: (error: any) => {
          observer.error(error);
      }})
    });
      return observableValue;
  }
}
