import { ICellRenderer } from "ag-grid-enterprise";
import { Component } from "@angular/core";

@Component({
    selector: 'currency-cell-renderer',
    templateUrl: 'currency-cell-renderer.component.html',
    styleUrls: ['./currency-cell-renderer.component.scss'],
  })
  export class CurrencyCellRendererComponent implements ICellRenderer {
    params!: any;
    public negativeCurrencyCssClass!: string;
    public currencyValue!: number;
  
    agInit(params: any): void {
      this.params = params;
      this.setParameters();
    }
  
    setParameters() {
      this.currencyValue = this.params.currencyValue;
      this.negativeCurrencyCssClass = this.currencyValue < 0 ? 'negativeCurrency' : '';
    }
  
    refresh(params: any) {
      this.params = params;
      this.setParameters();
      return true;
    }
  }
