import { ICellRenderer } from "ag-grid-enterprise";
import { Component } from "@angular/core";

@Component({
    selector: 'activity-name--cell-renderer',
    templateUrl: 'activity-name-cell-renderer.component.html',
    styleUrls: ['./activity-name-cell-renderer.component.scss'],
  })
  export class ActivityNameCellRendererComponent implements ICellRenderer  {
    params!: any;
    public isNonBillableActivity!: boolean;
    public activityName!: string;
    public tooltip: string = "";

    agInit(params: any): void {
      this.params = params;
      this.setParameters();
    }

    setParameters() {
      this.activityName = this.params.data?.activityName;
      this.isNonBillableActivity = this.params.data?.isNonBillableActivity;

      if (this.params.data?.modifiedByFullName != null && this.params.data?.modifiedDate != null) {
        this.tooltip = `Last Modified by ${this.params.data.modifiedByFullName} on ${new Date(this.params.data.modifiedDate).toLocaleString()}`
      }
    }

    refresh(params: any) {
      this.params = params;
      this.setParameters();
      return true;
    }
  }
