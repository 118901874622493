import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectPortfolioCoreRoutingModule } from './core-routing.module';
import { PageNotFoundComponent } from './components/page-not-found.component';
import { HomeComponent } from './components/home.component';
import { EmployeeService } from './services/employee.service';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { SessionStorageService } from './services/sessionStorage.service';
import { HttpQueueService } from './services/http-queue.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './error/http-error.interceptor';



@NgModule({
  declarations: [
    PageNotFoundComponent,
    HomeComponent
  ],
  imports: [
    CommonModule,
    ProjectPortfolioCoreRoutingModule,
    ToastNoAnimationModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true
    }
    )
  ],
  exports: [
    PageNotFoundComponent,
    HomeComponent
  ],
  providers: [
    EmployeeService,
    SessionStorageService,
    HttpQueueService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ]
})
export class ProjectPortfolioCoreModule { }
