import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { FilterMenu } from './filterMenu.enum';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  public isProjectPage: Subject<boolean> = new Subject<boolean>();

  public projectDetailsDisplayChanged: Subject<boolean> = new Subject<boolean>();
  public onProjectDetailsDisplayChanged: Observable<boolean> = this.projectDetailsDisplayChanged.asObservable();

  public saveToDisplayInBannerStarted: Subject<void> = new Subject();
  public onSaveToDisplayInBannerStarted: Observable<void> = this.saveToDisplayInBannerStarted.asObservable();
  public saveToDisplayInBannerCompleted: Subject<string> = new Subject();
  public onSaveToDisplayInBannerCompleted: Observable<string> = this.saveToDisplayInBannerCompleted.asObservable();

  public isFilterApplied: Subject<boolean> = new Subject<boolean>();
  public hideMenu: Subject<void> = new Subject<void>();

  public windowWidthChanged: Subject<number> = new Subject();
  public onWindowWidthChanged: Observable<number> = this.windowWidthChanged.asObservable();
  public openMenuName: Subject<FilterMenu> = new Subject();
  public openMenuNameWithFromApplied: Subject<boolean> = new Subject();

  public isMobileFilterDisplay: boolean = false;
  public isProjectDetailsOpen: boolean = true;
  //name of open menu
  private isMenuVisible: FilterMenu = FilterMenu.None;
  private isMenuVisibleWithFromApplied: boolean = false;
  private _windowWidth: number = 0;
  
  
  constructor() { }

  public mainLayoutProjects(value: boolean) {
    this.isProjectPage.next(value);
  }
  public mainLayoutDetailsOpen(value: boolean) {
    this.projectDetailsDisplayChanged.next(value);
    this.isProjectDetailsOpen = value;
  }
  //has there been a saved filter applied?
  public broadcastSavedFilterApplied(value: boolean) {
    this.isFilterApplied.next(value);
  }
  //click event in app component
  public broadcastClickOutsideMenu() {
    if (this.isMenuVisible !== null) {
      //send out broadcast to close any open menu
      this.hideMenu.next();
      //clear out visible menu variable
      this.isMenuVisible = FilterMenu.None;
    }
  }
  //is there an open menu?
  public setIsMenuVisible(value: FilterMenu) {
    this.isMenuVisible = value;
    this.openMenuName.next(this.isMenuVisible);
  }
  //is there a filter menu open with from/caller
  public setIsMenuOpenWithFromAppliedFilter(value: boolean) {
    this.isMenuVisibleWithFromApplied = value;
    this.openMenuNameWithFromApplied.next(this.isMenuVisibleWithFromApplied);
  }

  public setMobileProjectFilterDisplay(): void {

    if (this.isProjectDetailsOpen) {
      if (this._windowWidth >= 1024) {
        this.isMobileFilterDisplay = false;
      } else {
        this.isMobileFilterDisplay = true;
      }
    } else {
      if (this._windowWidth >= 720) {
        this.isMobileFilterDisplay = false;
      } else {
        this.isMobileFilterDisplay = true;
      }
    }
  }
}


