import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";

export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle((req))
        .pipe(
          catchError((err: HttpErrorResponse) => {
            console.log(err);
            let message = "";
            if (err && err.status === 403) {
              message = "The current user is unauthorized to perform this operation.";
            }
            else if (err && err.error) {
              message = err.error;
            } else {
              message = "An error has occurred, please refresh and try again.";
            }
            return throwError(message);
          })
        );
    }
  
}
