import { DepartmentLookup } from "./department-lookup.model";
import { FlagLookup } from "./flag-lookup.model";
import { OfficeLookup } from "./office-lookup.model";
import { OwnerClientTypeLookup } from "./owner-client-type-lookup.model";
import { ProjectManagerEmployeeLookup } from "./project-manager-employee-lookup.model";
import { ProjectManager1DepartmentLookup } from "./project-manager1-department-lookup.model";
import { ProjectManager1OfficeLookup } from "./project-manager1-office-lookup.model";

export class ProjectFilterLookupContainer {
    public departments: DepartmentLookup[] = [];
    public flags: FlagLookup[] = [];
    public offices: OfficeLookup[] = [];
    public ownerClientTypes: OwnerClientTypeLookup[] = [];
    public projectManager1Departments: ProjectManager1DepartmentLookup[] = [];
    public projectManager1Offices: ProjectManager1OfficeLookup[] = [];
    public projectManager1Employees: ProjectManagerEmployeeLookup[] = [];
    public projectManager2Employees: ProjectManagerEmployeeLookup[] = [];
}