
import { defer as observableDefer, concat as observableConcat, Observable, Subscription, defer } from 'rxjs';
import { Component, OnInit } from "@angular/core";

import { LoaderService } from "../../services/loader.service";
import { ObservableLoader } from "../../models/loader.model";
import { finalize, retry } from "rxjs/operators";

@Component({
  selector: "loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"]
})

export class LoaderComponent implements OnInit {
  public text?: string | null;
  public showLoader: boolean = false;

  constructor(public loaderService: LoaderService) {
    this.loaderService.register(this);
  }

  public ngOnInit() {
    this.updateLoader();
  }

  private updateLoader(): void {
    this.loaderService.getLoader().subscribe(result => {
      this.showLoader = result.showLoader;
      this.text = result.text;
    });
  }

  public load(observables: ObservableLoader[]): Observable<any> {
    return this.runObservables(observables);
  }

  public runObservables(observables: ObservableLoader[]): Observable<any> {
    return defer(() => {
      this.showLoader = true;

      return observableConcat(
        ...(observables.map(x => observableDefer(() => {
          this.text = x.text;
          return x.observable
        })))
      ).pipe(
        retry(5),
        finalize(() => {
          this.text = null;
          this.showLoader = false;
        })
      );
    });
  }
}
