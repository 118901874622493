import { LicenseManager } from 'ag-grid-enterprise';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-049717 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Johnson, Mirmiran & Thompson, Inc. )_is_granted_a_( Multiple Applications )_Developer_License_for_( 7 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 7 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 5 March 2025 )____[v2]_MTc0MTEzMjgwMDAwMA==0f2cd7d7465a1234d1857645752bade9");

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
