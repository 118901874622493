import { Injectable } from '@angular/core';
import { SINGLETON_INJECTABLE_TYPE } from '../../common/models/constants.model';
import { AuthService } from '../../core/services/auth.service';
import { ProjectService } from '../../projects/services/project.service';

@Injectable({
  providedIn: SINGLETON_INJECTABLE_TYPE,
})
export class ProjectUserPermissionService {

  constructor(
    private authService: AuthService, private projectService: ProjectService
  ) {
  }

  public get hasUserPermissionForAddActionNoteOrViewHistoryPage(): boolean {
    return ( this.authService.userRoleTypes.isPM && (this.projectService.isPmForSelectedProject || this.projectService.isPmForAnySelectedProjectInContract))
      || this.authService.userRoleTypes.isAdmin || this.authService.userRoleTypes.isElevated || this.projectService.isTaskManagerForSelectedProject
          || this.authService.userRoleTypes.isGeneralRoleType;
  }

  public get hasPermissionToReviewProject(): boolean {
    return this.authService.userRoleTypes.isSupervisor;
  }
}
